export class Constants {
  public static Countries: any[] = [
    {
      Id: "CA",
      Name: "Canada",
    },
  ];

  public static Active: any[] = [
    {
      Id: 1,
      Name: "Yes",
    },
    {
      Id: 0,
      Name: "No",
    },
  ];

  public static Role: any[] = [
    {
      Id: 0,
      Name: "Not Assigned",
    },
    {
      Id: 1,
      Name: "Inspector",
    },
    {
      Id: 2,
      Name: "Manager",
    },
    { Id: 99, Name: "Admin" },
  ];

  public static Provinces: any[] = [
    {
      Id: "NS",
      Name: "Nova Scotia",
    },
  ];

  public static Status: any[] = [
    {
      Id: 1,
      Name: "Opened",
    },
    {
      Id: 0,
      Name: "Closed",
    },
  ];

  public static Cities: any[] = [
    { Id: "Albert Bridge", Name: "Albert Bridge" },
    { Id: "Amherst", Name: "Amherst" },
    { Id: "Annapolis Royal", Name: "Annapolis Royal" },
    { Id: "Antigonish", Name: "Antigonish" },
    { Id: "Arcadia", Name: "Arcadia" },
    { Id: "Argyle", Name: "Argyle" },
    { Id: "Arichat", Name: "Arichat" },
    { Id: "Bayview", Name: "Bayview" },
    { Id: "Beaverbank", Name: "Beaverbank" },
    { Id: "Bedford", Name: "Bedford" },
    { Id: "Beechville", Name: "Beechville" },
    { Id: "Bible Hill", Name: "Bible Hill" },
    { Id: "Boutiliers Point", Name: "Boutiliers Point" },
    { Id: "Bridgetown", Name: "Bridgetown" },
    { Id: "Bridgewater", Name: "Bridgewater" },
    { Id: "Cambridge", Name: "Cambridge" },
    { Id: "Canso", Name: "Canso" },
    { Id: "Chebogue Point", Name: "Chebogue Point" },
    { Id: "Chester", Name: "Chester" },
    { Id: "Cheticamp", Name: "Cheticamp" },
    { Id: "Cole Harbour", Name: "Cole Harbour" },
    { Id: "Collingwood", Name: "Collingwood" },
    { Id: "Conrod Settlement", Name: "Conrod Settlement" },
    { Id: "Currys Corner", Name: "Currys Corner" },
    { Id: "Dartmouth", Name: "Dartmouth" },
    { Id: "Digby", Name: "Digby" },
    { Id: "Dominion", Name: "Dominion" },
    { Id: "Durham", Name: "Durham" },
    { Id: "East Amherst", Name: "East Amherst" },
    { Id: "Eastern Passage", Name: "Eastern Passage" },
    { Id: "Ellershouse", Name: "Ellershouse" },
    { Id: "Enfield", Name: "Enfield" },
    { Id: "Fall River", Name: "Fall River" },
    { Id: "Falmouth", Name: "Falmouth" },
    { Id: "Five Islands", Name: "Five Islands" },
    { Id: "Frasers Mountain", Name: "Frasers Mountain" },
    { Id: "Georges River", Name: "Georges River" },
    { Id: "Glace Bay", Name: "Glace Bay" },
    { Id: "Grafton", Name: "Grafton" },
    { Id: "Greenfield", Name: "Greenfield" },
    { Id: "Greenwood", Name: "Greenwood" },
    { Id: "Halifax", Name: "Halifax" },
    { Id: "Hammonds Plains", Name: "Hammonds Plains" },
    { Id: "Hantsport", Name: "Hantsport" },
    { Id: "Hardwood Lands", Name: "Hardwood Lands" },
    { Id: "Harrietsfield", Name: "Harrietsfield" },
    { Id: "Hatchet Lake", Name: "Hatchet Lake" },
    { Id: "Head of St. Margarets Bay", Name: "Head of St. Margarets Bay" },
    { Id: "Herring Cove", Name: "Herring Cove" },
    { Id: "Hilden", Name: "Hilden" },
    { Id: "Howie Centre", Name: "Howie Centre" },
    { Id: "Hubley", Name: "Hubley" },
    { Id: "Italy Cross", Name: "Italy Cross" },
    { Id: "Kennetcook", Name: "Kennetcook" },
    { Id: "Kentville", Name: "Kentville" },
    { Id: "Kingston", Name: "Kingston" },
    { Id: "Kitchener", Name: "Kitchener" },
    { Id: "Lake Echo", Name: "Lake Echo" },
    { Id: "Lawrencetown", Name: "Lawrencetown" },
    { Id: "Lingan", Name: "Lingan" },
    { Id: "Lockeport", Name: "Lockeport" },
    { Id: "Louisdale", Name: "Louisdale" },
    { Id: "Lower Argyle", Name: "Lower Argyle" },
    { Id: "Lower Sackville", Name: "Lower Sackville" },
    { Id: "Lucasville", Name: "Lucasville" },
    { Id: "Lunenburg", Name: "Lunenburg" },
    { Id: "Mclellans Brook", Name: "Mclellans Brook" },
    { Id: "Melvern Square", Name: "Melvern Square" },
    { Id: "Meteghan", Name: "Meteghan" },
    { Id: "Middle Musquodoboit", Name: "Middle Musquodoboit" },
    { Id: "Middle Sackville", Name: "Middle Sackville" },
    { Id: "Middle West Pubnico", Name: "Middle West Pubnico" },
    { Id: "Middleton", Name: "Middleton" },
    { Id: "Milford", Name: "Milford" },
    { Id: "Mill Creek", Name: "Mill Creek" },
    { Id: "Neils Harbour", Name: "Neils Harbour" },
    { Id: "New Germany", Name: "New Germany" },
    { Id: "New Glasgow", Name: "New Glasgow" },
    { Id: "New Victoria", Name: "New Victoria" },
    { Id: "Newport Station", Name: "Newport Station" },
    { Id: "North Kentville", Name: "North Kentville" },
    { Id: "North River", Name: "North River" },
    { Id: "North Riverside", Name: "North Riverside" },
    { Id: "Northport", Name: "Northport" },
    { Id: "Oakfield", Name: "Oakfield" },
    { Id: "Oxford", Name: "Oxford" },
    { Id: "Parrsboro", Name: "Parrsboro" },
    { Id: "Pictou", Name: "Pictou" },
    { Id: "Pine Grove", Name: "Pine Grove" },
    { Id: "Port Hastings", Name: "Port Hastings" },
    { Id: "Port Hawkesbury", Name: "Port Hawkesbury" },
    { Id: "Port Hood", Name: "Port Hood" },
    { Id: "Port Mouton", Name: "Port Mouton" },
    { Id: "Port Williams", Name: "Port Williams" },
    { Id: "Reserve Mines", Name: "Reserve Mines" },
    { Id: "Rinescreek", Name: "Rinescreek" },
    { Id: "River Bourgeois", Name: "River Bourgeois" },
    { Id: "Sackville", Name: "Sackville" },
    { Id: "Salmon River", Name: "Salmon River" },
    { Id: "Saulnierville", Name: "Saulnierville" },
    { Id: "Shelburne County", Name: "Shelburne County" },
    { Id: "Sherbrooke", Name: "Sherbrooke" },
    { Id: "South Alton", Name: "South Alton" },
    { Id: "South Berwick", Name: "South Berwick" },
    { Id: "Springhill", Name: "Springhill" },
    { Id: "St. Andrews", Name: "St. Andrews" },
    { Id: "St. Peters", Name: "St. Peters" },
    { Id: "Steam Mill", Name: "Steam Mill" },
    { Id: "Stellarton", Name: "Stellarton" },
    { Id: "Sydney", Name: "Sydney" },
    { Id: "Sydney Mines", Name: "Sydney Mines" },
    { Id: "Sydney River", Name: "Sydney River" },
    { Id: "Tatamagouche", Name: "Tatamagouche" },
    { Id: "Three Fathom Harbour", Name: "Three Fathom Harbour" },
    { Id: "Timberlea", Name: "Timberlea" },
    { Id: "Tremont", Name: "Tremont" },
    { Id: "Trenton", Name: "Trenton" },
    { Id: "Truro", Name: "Truro" },
    { Id: "Tusket", Name: "Tusket" },
    { Id: "Two Islands", Name: "Two Islands" },
    { Id: "Upper Canard", Name: "Upper Canard" },
    { Id: "Upper Economy", Name: "Upper Economy" },
    { Id: "Upper Onslow", Name: "Upper Onslow" },
    { Id: "Upper Tantallon", Name: "Upper Tantallon" },
    { Id: "Valley", Name: "Valley" },
    { Id: "Warren", Name: "Warren" },
    { Id: "Waverley", Name: "Waverley" },
    { Id: "Wellington", Name: "Wellington" },
    { Id: "Welsford", Name: "Welsford" },
    { Id: "Westmount", Name: "Westmount" },
    { Id: "Westville", Name: "Westville" },
    { Id: "Whitby", Name: "Whitby" },
    { Id: "Wolfville", Name: "Wolfville" },
    { Id: "Woodstock", Name: "Woodstock" },
    { Id: "Yarmouth", Name: "Yarmouth" },
  ];

  public static Companies: any[] = [
    { Id: "Atlantic Wholesalers", Name: "Atlantic Wholesalers" },
    { Id: "Costco Pharmacies", Name: "Costco Pharmacies" },
    { Id: "Guardian Health", Name: "Guardian Health" },
    { Id: "Independent Pharmacies", Name: "Independent Pharmacies" },
    { Id: "Lawtons Drug Store Ltd.", Name: "Lawtons Drug Store Ltd." },
    { Id: "Pharmachoice", Name: "Pharmachoice" },
    { Id: "Pharmasave", Name: "Pharmasave" },
    { Id: "Shoppers Drug Mart", Name: "Shoppers Drug Mart" },
    { Id: "Sobeys Pharmacy", Name: "Sobeys Pharmacy" },
    { Id: "The Medicine Shoppe", Name: "The Medicine Shoppe" },
    { Id: "Wal-Mart Pharmacy", Name: "Wal-Mart Pharmacy" },
  ];
}
