import { Component, Inject } from "@angular/core";
import { DialogService } from "../services/dialog.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
export interface DialogData {
  message: string;
  detail: string;
}

@Component({
  selector: "dialog-error-message",
  templateUrl: "dialog-error-message.html",
  styleUrls: ["dialog-error-message.scss"],
})
export class DialogErrorComponent {
  constructor(
    public dialogService: DialogService,
    public config: DynamicDialogConfig
  ) {}
}
