import { Inspection } from "./inspection.model";

export class Inspector {
    public id: number ;
    public name: string;
    public addressLine1: string;
    public addressLine2: string;
    public city: string;
    public country: string;
    public province: string;
    public postalCode: string;
    public primaryPhone: string;
    public secondaryPhone: string;
    public email: string;
    public active: number;
    public inspection: Inspection
}

    

