import { Component, OnInit } from "@angular/core";
import { Pharmacy } from "../pharmacy.model";
import { PharmacyService } from "../../services/pharmacy.service";
import { DialogService } from "../../services/dialog.service";
import { Router } from "@angular/router";
import { OrderPipe } from "ngx-order-pipe";
import { Constants } from "../../services/constant-service";
import { InspectorService } from "../../services/inspector.service";

@Component({
  selector: "app-pharmacy-edit",
  templateUrl: "./pharmacy-edit.component.html",
  styleUrls: ["./pharmacy-edit.component.scss"],
})
export class PharmacyEditComponent implements OnInit {
  model = new Pharmacy();
  listActive: any;
  listStatus: any;
  listCountries: any;
  listProvinces: any;
  listCities: any;
  listCompanies: any;
  listCitiesOrdered: any[];
  listInspectors: any[] = [];

  constructor(
    private router: Router,
    private pharmacyService: PharmacyService,
    private inspectorService: InspectorService,
    private dialogService: DialogService,
    private orderPipe: OrderPipe
  ) {}

  ngOnInit() {
    let pharmacyID = localStorage.getItem("editPharmacyId");

    if (!pharmacyID) {
      this.model = new Pharmacy();
      this.model.country = "CA";
      this.model.province = "NS";
      this.model.active = 1;
      this.model.status = 1;
      this.model.city = "Halifax";
      this.model.in1touch_id = 0;
    } else {
      this.pharmacyService.getPharmacy(pharmacyID).subscribe((data) => {
        this.model = data;
      });
    }

    localStorage.clear();

    // Load lists
    this.listActive = Constants.Active;
    this.listCountries = Constants.Countries;
    this.listProvinces = Constants.Provinces;
    this.listStatus = Constants.Status;
    this.listCities = Constants.Cities;
    this.listCompanies = Constants.Companies;

    this.listInspectors = [0];

    this.inspectorService.getInspectors().subscribe(
      (data) => {
        this.listInspectors = data;
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
      }
    );

    // Sort cities list
    this.listCitiesOrdered = this.orderPipe.transform(this.listCities, "Name");
  }

  public previousForm() {
    this.router.navigateByUrl("/pharmacy");
  }

  public onSubmit() {
    if (this.model.id) {
      this.pharmacyService
        .updatePharmacy("", this.model, this.model.id)
        .subscribe(
          (res) => {
            this.dialogService.openDialog(
              "Pharmacy record updated",
              "Licence # " +
                res.licenseNumber +
                ", Name: " +
                res.pharmacyName +
                ", Company Name: " +
                res.companyName
            );
          },
          (error) => {
            console.log(error);

            this.dialogService.openDialogError(error[0], error[1]);
          },
          () => {
            this.model = new Pharmacy();
            this.previousForm();
          }
        );
    } else {
      this.pharmacyService.addPharmacy("", this.model).subscribe(
        (res) => {
          this.dialogService.openDialog(
            "Pharmacy record created",
            JSON.stringify(res)
          );
        },
        (error) => {
          this.dialogService.openDialogError(error[0], error[1]);
        },
        () => {
          this.model = new Pharmacy();
          this.previousForm();
        }
      );
    }
  }
}
