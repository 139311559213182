import { Component } from "@angular/core";
import { Observable, of } from "rxjs";
import { Document } from "./document.model";
import { DialogService } from "../services/dialog.service";
import { DocumentService } from "../services/document.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.scss"],
})
export class DocumentComponent {
  myRowData: Observable<Document[]> = null;

  myColDefs = [
    { headerName: "Id", field: "id", width: 130, checkboxSelection: true },
    { headerName: "Name", field: "name", suppressSizeToFit: false, width: 350 },
    {
      headerName: "Created At",
      field: "created_at",
      suppressSizeToFit: false,
      width: 250,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleString("en-GB", { timeZone: "UTC" })
          : "";
      },
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      suppressSizeToFit: false,
      width: 250,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleString("en-GB", { timeZone: "UTC" })
          : "";
      },
    },
  ];

  gridOptions = {
    // PROPERTIES - object properties, myRowData and myColDefs are created somewhere in your application
    rowData: this.myRowData,
    columnDefs: this.myColDefs,

    // PROPERTIES - simple boolean / string / number properties
    pagination: true,
    rowSelection: "single",

    gridApi: null,
    gridColumnApi: null,

    onGridReady: function (event) {
      this.gridApi = this.api;
      this.gridColumnApi = this.columnApi;
    },
    defaultColDef: {
      sortable: true,
      filter: true,
    },
  };

  constructor(
    private inspectionDocumentService: DocumentService,
    private _router: Router,
    private dialogService: DialogService
  ) {}

  onRemoveSelected() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var res = this.gridOptions.gridApi.updateRowData({ remove: selectedData });
    var id = res.remove[0].data.id;

    this.inspectionDocumentService.deleteInspectionDocument(id).subscribe(
      (res) => {
        this.dialogService.openDialog(
          "Inspection document record deleted",
          JSON.stringify(res)
        );
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
        console.log(error);
      }
    );
  }

  onCloneSelected() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var id = selectedData[0].id;

    this.inspectionDocumentService.cloneInspectionDocument(id).subscribe(
      (res) => {
        this.inspectionDocumentService
          .getInspectionDocuments()
          .subscribe((res) => {
            this.myRowData = of(res);
            this.gridOptions.gridApi.setRowData(this.gridOptions.rowData);
            this.dialogService.openDialog(
              "Inspection document record cloned",
              JSON.stringify(res)
            );
          });
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
        console.log(error);
      }
    );
  }

  onUpdateData() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var id = selectedData[0].id;

    localStorage.setItem("editInspectionDocumentId", id.toString());

    this._router.navigate(["inspection-document/edit/" + id.toString()]);
  }

  onAddData() {
    this._router.navigate(["inspection-document/edit"]);
  }

  ngOnInit() {
    this.myRowData = this.inspectionDocumentService.getInspectionDocuments();
  }
}
