import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../services/user.model";
import { UserService } from "../services/user.service";
import { DialogService } from "../services/dialog.service";
import { Router } from "@angular/router";
import { Constants } from "../services/constant-service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  myRowData: Observable<User[]> = null;

  myColDefs = [
    {
      headerName: "Name",
      field: "name",
      suppressSizeToFit: false,
      width: 250,
      checkboxSelection: true,
    },
    { headerName: "Email", field: "email", width: 250 },
    { headerName: "Created At", field: "created_at", width: 250 },
    { headerName: "Updated At", field: "updated_at", width: 250 },
    {
      headerName: "Role",
      field: "role",
      width: 160,
      valueFormatter: this.roleFormatter,
    },
    {
      headerName: "Active",
      field: "active",
      width: 160,
      valueFormatter: this.activeFormatter,
    },
  ];

  gridOptions = {
    // PROPERTIES - object properties, myRowData and myColDefs are created somewhere in your application
    rowData: this.myRowData,
    columnDefs: this.myColDefs,

    // PROPERTIES - simple boolean / string / number properties
    pagination: true,
    rowSelection: "single",
    gridApi: null,
    gridColumnApi: null,

    onGridReady: function (event) {
      this.gridApi = this.api;
      this.gridColumnApi = this.columnApi;
    },
    defaultColDef: {
      sortable: true,
      filter: true,
    },
  };

  constructor(
    private _router: Router,
    private userService: UserService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.myRowData = this.userService.getUsers();
  }

  onRemoveSelected() {
    // tslint:disable-next-line:prefer-const
    let selectedData = this.gridOptions.gridApi.getSelectedRows();
    const res = this.gridOptions.gridApi.updateRowData({
      remove: selectedData,
    });
    console.log(res.remove[0].data.id);
    const id = res.remove[0].data.id;
  }

  onUpdateData() {
    const selectedData = this.gridOptions.gridApi.getSelectedRows();
    const id = selectedData[0].id;

    localStorage.setItem("editUserId", id.toString());

    this._router.navigate(["user/edit/" + id.toString()]);
  }

  activeFormatter(params) {
    let active;

    active = Constants.Active.find((x) => x.Id == params.value).Name;

    return active;
  }

  roleFormatter(params) {
    let role;

    role = Constants.Role.find((x) => x.Id == params.value).Name;

    return role;
  }
}
