<p-card>
  <h4>Inspector</h4>
</p-card>

<ag-grid-angular
  id="myGrid"
  style="width: 100%; height: 535px"
  class="ag-theme-material"
  [rowData]="myRowData | async"
  [columnDefs]="myColDefs"
  [paginationAutoPageSize]="true"
  [pagination]="true"
  [gridOptions]="gridOptions"
  [animateRows]="true"
>
</ag-grid-angular>

<button type="button" class="btn btn-success" (click)="onAddData()">
  <span class="glyphicon glyphicon-plus"></span> Add
</button>
<button type="button" class="btn btn-info" (click)="onUpdateData()">
  <span class="glyphicon glyphicon-pencil"></span> Edit
</button>
<button type="button" class="btn btn-danger" (click)="onRemoveSelected()">
  <span class="glyphicon glyphicon-trash"></span> Delete
</button>
