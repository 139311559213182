// import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DialogComponent } from "../dialog/dialog.component";
import { DialogErrorComponent } from "../dialogerror/dialog-error-message.component";
import { DialogService as test, DynamicDialogRef } from "primeng/dynamicdialog";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(public dialogServicePrime: test) {}

  ref: DynamicDialogRef | undefined;

  openDialog(header: string, detail: string): void {
    this.ref = this.dialogServicePrime.open(DialogComponent, {
      width: "550px",
      data: detail,
      header: header,
    });
  }

  openDialogError(header: string, detail: string) {
    this.ref = this.dialogServicePrime.open(DialogErrorComponent, {
      width: "550px",
      data: detail,
      header: header,
    });
  }
}
