import { Component, OnInit, ViewChild } from "@angular/core";
import { PharmacyService } from "../services/pharmacy.service";
import { DialogService } from "../services/dialog.service";
import { Constants } from "../services/constant-service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-in1touch-pharmacy",
  templateUrl: "./in1touch-pharmacy.component.html",
  styleUrls: ["./in1touch-pharmacy.component.scss"],
})
export class In1touchPharmacyComponent implements OnInit {
  public csvRecords: any;
  myRowData: any[] = [];

  myColDefs = [
    {
      headerName: "License #",
      field: "licenseNumber",
      width: 130,
      suppressSizeToFit: true,
    },
    {
      headerName: "Name",
      field: "pharmacyName",
      suppressSizeToFit: false,
      width: 250,
    },
    { headerName: "Company Name", field: "companyName" },
    { headerName: "Address", field: "addressLine1", width: 250 },
    { headerName: "City", field: "city", width: 140 },
    { headerName: "Manager", field: "manager", width: 200 },
    { headerName: "Phone #1", field: "primaryPhone", width: 160 },
    {
      headerName: "Status",
      field: "status",
      valueFormatter: this.statusFormatter,
      suppressSizeToFit: true,
      width: 120,
    },
  ];

  @ViewChild("fileImportInput", { static: false }) fileImportInput: any;

  constructor(
    private pharmacyService: PharmacyService,
    private dialogService: DialogService,
    private messageService: MessageService
  ) {}

  ngOnInit() {}

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = csvRecordsArr[0].split(",");
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    var dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let data = csvRecordsArray[i].split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g);

      // FOR EACH ROW IN CSV FILE IF THE NUMBER OF COLUMNS
      // ARE SAME AS NUMBER OF HEADER COLUMNS THEN PARSE THE DATA

      if (data.length == headerLength) {
        // Check for inconsistencies (Company name and licence number)
        if (data[1].trim() == "") {
          this.dialogService.openDialogError(
            `Please check your file for inconsistencies. The data for the pharmacy '${data[0].trim()}' will not be imported`,
            `Company name is empty.`
          );
          continue;
        }

        // Check for inconsistencies (Company name and licence number)
        if (data[2].trim() == "") {
          this.dialogService.openDialogError(
            `Please check your file for inconsistencies. The data for the pharmacy '${data[0].trim()}' will not be imported`,
            `Licence number is empty.`
          );
          continue;
        }

        var csvRecord: CSVRecord = new CSVRecord();

        csvRecord.pharmacyName = data[0].trim();
        csvRecord.companyName = data[1].trim();
        csvRecord.licenseNumber = data[2].trim();
        csvRecord.addressLine1 = data[3].trim().replace(/^"(.*)"$/, "$1");
        csvRecord.addressLine2 = data[4].trim().replace(/^"(.*)"$/, "$1");
        csvRecord.city = data[5].trim();
        csvRecord.province = data[6].trim();
        csvRecord.country = data[7].trim();
        csvRecord.postalCode = data[8].trim();
        csvRecord.primaryPhone = data[9].trim();
        csvRecord.secondaryPhone = data[10].trim();
        csvRecord.fax = data[11].trim();
        csvRecord.email = data[12].trim();
        csvRecord.website = data[13].trim();
        csvRecord.in1touch_id = data[18].trim();
        csvRecord.managerFirstName = data[19].trim();
        csvRecord.managerLastName = data[20].trim();

        if (data[14].trim() == "Yes") {
          csvRecord.active = "1";
        } else {
          csvRecord.active = "0";
        }

        csvRecord.manager = `${csvRecord.managerFirstName} ${csvRecord.managerLastName}`;
        csvRecord.alternateManager = data[16].trim();

        if (data[17].trim() == "Opened") {
          csvRecord.status = "1";
        } else {
          csvRecord.status = "0";
        }

        dataArr.push(csvRecord);
      } else {
        let pharmacyNameMessage = "";

        if (data[0].trim() == "") {
          pharmacyNameMessage = "<Empty>";
        } else {
          pharmacyNameMessage = data[0].trim();
        }

        console.log(
          "Record for the following pharmacy not complete: " +
            pharmacyNameMessage
        );
      }
    }
    return dataArr;
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = "";
    this.csvRecords = [];
  }

  importRecords() {
    this.dialogService.openDialog(
      "Import process started. You'll get a notification when this process end.",
      "Import process started. You'll get a notification when this process end."
    );

    var promiseCollection = [];

    this.csvRecords.forEach((element) => {
      var promiseItem = new Promise<void>((resolve, reject) => {
        this.pharmacyService.addPharmacy("", element).subscribe((res) => {
          resolve();
        });
      });

      promiseCollection.push(promiseItem);
    });

    Promise.all(promiseCollection).then(() => {
      this.messageService.add({
        severity: "success",
        summary: "In1Touch Pharmacy",
        detail: "Records imported",
        sticky: true,
      });
    });
  }

  fileChangeListener($event: any): void {
    var files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {
      var input = $event.target;
      var reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;

        let csvRecordsArray = (<String>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        this.csvRecords = this.getDataRecordsArrayFromCSVFile(
          csvRecordsArray,
          headersRow.length
        );

        this.myRowData = this.csvRecords;
      };

      reader.onerror = function () {
        alert("Unable to read " + JSON.stringify(input.files[0]));
      };
    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  statusFormatter(params) {
    status;

    status = Constants.Status.find((x) => x.Id == params.value).Name;

    return status;
  }
}

export class CSVRecord {
  public pharmacyName: string;
  public companyName: string;
  public licenseNumber: string;
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public province: string;
  public country: string;
  public postalCode: string;
  public primaryPhone: string;
  public secondaryPhone: string;
  public fax: string;
  public email: string;
  public website: string;
  public active: string;
  public manager: string;
  public alternateManager: string;
  public status: string;
  public in1touch_id: string;
  public managerFirstName: string;
  public managerLastName: string;
}
