<p style="font-weight: bold">Professional Practice Audit</p>

<form (ngSubmit)="submit()">
  <p-accordion>
    <p-accordionTab
      *ngFor="let document of documentData; let i = index"
      header="{{ document.description }}"
    >
      <p-tabView>
        <p-tabPanel
          [selected]="j === 0"
          *ngFor="let section of document.children; let j = index"
          header="{{ section.description }}"
        >
          <div class="row" *ngFor="let item of section.children; let k = index">
            <p-triStateCheckbox
              name="checkbox-{{
                this.documentData[i].children[j].children[k].id
              }}"
              [(ngModel)]="this.documentData[i].children[j].children[k].value"
              label="{{ item.description }}"
            >
            </p-triStateCheckbox>
          </div>
        </p-tabPanel>
      </p-tabView>
    </p-accordionTab>
  </p-accordion>
</form>
