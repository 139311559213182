import { Component, OnInit } from "@angular/core";
import { User } from "../../services/user.model";
import { UserService } from "../../services/user.service";
import { InspectorService } from "../../services/inspector.service";
import { DialogService } from "../../services/dialog.service";
import { Router } from "@angular/router";
import { Constants } from "../../services/constant-service";

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
  model = new User();
  listRoles: any;
  listActive: any;
  listInspectors: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private inspectorService: InspectorService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    const userID = localStorage.getItem("editUserId");

    this.userService.getUser(userID).subscribe((data) => {
      this.model = data;
    });

    localStorage.clear();

    this.listRoles = Constants.Role;
    this.listActive = Constants.Active;

    this.inspectorService.getInspectors().subscribe((res) => {
      this.listInspectors = res;
    });
  }

  public previousForm() {
    this.router.navigateByUrl("/user");
  }

  public onSubmit() {
    if (this.model.id) {
      this.userService.updateUser("", this.model, this.model.id).subscribe(
        (res) => {
          this.dialogService.openDialog(
            "User record updated",
            JSON.stringify(res)
          );

          this.previousForm();
        },
        (error) => {
          console.log(error);

          this.dialogService.openDialogError(error[0], error[1]);
        }
      );
    }
  }
}
