<p-card>
  <h4>Global Settings</h4>
</p-card>

<div class="edit-global-setting-form-container">
  <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
      <div class="col-md-4">
        <label for="idPpaVersion"
          >Professional Practice Audit Document - Current version</label
        >
        <select
          id="idPpaVersion"
          #Id="ngModel"
          class="hideLabel form-control"
          [(ngModel)]="model.idPpaVersion"
          name="idPpaVersion"
          required
          #idPpaVersion="ngModel"
        >
          <option [ngValue]="k.id" *ngFor="let k of listInspectionDocuments">
            {{ k.name }}
          </option>
          <div
            *ngIf="f.submitted && idPpaVersion.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="idPpaVersion.errors.required">
              Document version required
            </div>
          </div>
        </select>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-success">
        <span class="glyphicon glyphicon-ok"></span> Save
      </button>
    </div>
  </form>
</div>
