<p-card>
  <h4>{{ title }}</h4>
</p-card>

<div class="edit-form-container">
  <p-treeTable
    #documentPracticeStd
    [columns]="cols"
    [value]="tableData"
    selectionMode="single"
    [scrollable]="true"
    selectionMode="single"
    scrollHeight="320px"
    [(selection)]="selectedNode"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" />
      </colgroup>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-rowNode
      let-rowData="rowData"
      let-columns="columns"
    >
      <tr [ttSelectableRow]="rowNode">
        <td
          *ngFor="let col of columns; let i = index"
          ttEditableColumn
          [ngClass]="{ 'p-toggler-column': i === 0 }"
        >
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0">
          </p-treeTableToggler>

          <p-treeTableCellEditor>
            <ng-template pTemplate="input">
              <input
                pInputText
                type="text"
                [(ngModel)]="rowData[col.field]"
                [ngStyle]="{ width: i == 0 ? '80%' : '90%' }"
              />

              <button
                (click)="addNode()"
                type="button"
                class="p-element p-ripple p-button-rounded p-button-secondary p-button-text p-button p-component p-button-icon-only"
                [style]="{ 'margin-right': '.2em', 'margin-left': '1em' }"
                [disabled]="preventAddNode()"
              >
                <span class="p-button-icon pi pi-plus" aria-hidden="true">
                </span
                ><span
                  class="p-ink"
                  style="height: 42px; width: 42px; top: -0.75px; left: 3px"
                ></span>
              </button>

              <button
                (click)="removeNode()"
                type="button"
                class="p-element p-ripple p-button-rounded p-button-secondary p-button-text p-button p-component p-button-icon-only"
                [style]="{ 'margin-right': '.2em', 'margin-left': '1em' }"
              >
                <span class="p-button-icon pi pi-minus" aria-hidden="true">
                </span
                ><span
                  class="p-ink"
                  style="height: 42px; width: 42px; top: -0.75px; left: 3px"
                ></span>
              </button>
            </ng-template>

            <ng-template pTemplate="output">
              {{ rowData[col.field] }}
            </ng-template>
          </p-treeTableCellEditor>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>

  <form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate>
    <div class="edit-form-container">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="documentName">Document name</label>
            <input
              type="text"
              class="form-control"
              name="documentName"
              id="documentName"
              [(ngModel)]="model.name"
              required
              #documentName="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && documentName.invalid }"
            />
            <div
              *ngIf="f.submitted && documentName.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="documentName.errors.required">
                Document Name is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-warning" type="button" (click)="addRootNode()">
        <span class="glyphicon glyphicon-ok"></span> Add
      </button>

      <button class="btn btn-success" (click)="onSubmit()">
        <span class="glyphicon glyphicon-ok"></span> Save
      </button>

      <button class="btn btn-info" type="button" (click)="previousForm()">
        <span class="glyphicon glyphicon-chevron-left"></span> Back
      </button>
    </div>
  </form>
</div>
