import { Injectable, isDevMode } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private tokenService: AngularTokenService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('accessToken');

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        
        if (!isDevMode) {
            request = request.clone({ url: request.url.replace('http://', 'https://')});
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.tokenService.signOut().pipe(take(1)).subscribe(
                        (x) => {
                            this.router.navigate(['login']);
                            console.log('Successful logout...')}
                    );
                }
                return throwError(error);
            }));
    }
}