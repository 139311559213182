import { Component, OnDestroy } from "@angular/core";
import { BreadcrumbService } from "./services/breadcrumb.service";
import { Subscription } from "rxjs";
import { MenuItem } from "primeng/api/menuitem";
import { AngularTokenService } from "angular-token";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./app.breadcrumb.component.html",
})
export class AppBreadcrumbComponent implements OnDestroy {
  subscription: Subscription;

  items: MenuItem[];

  constructor(
    public breadcrumbService: BreadcrumbService,
    public tokenservice: AngularTokenService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
    });
  }

  signOut() {
    this.tokenservice.signOut().subscribe((response) => {});
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
