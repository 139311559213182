import { Component, OnInit, Input } from "@angular/core";
import { InspectionService } from "../services/inspection.service";
import { Inspection } from "../services/inspection.model";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { DialogService } from "../services/dialog.service";
import { PharmacyService } from "../services/pharmacy.service";
import { Pharmacy } from "../services/pharmacy.model";
import { UserService } from "../services/user.service";
import { UserRole } from "../services/enumeration";

@Component({
  selector: "app-inspection",
  templateUrl: "./inspection.component.html",
  styleUrls: ["./inspection.component.scss"],
})
export class InspectionComponent implements OnInit {
  myRowData: Observable<Inspection[]> = null;
  model: Inspection;
  single: any;
  private isAdminOrManager: boolean = true;

  public pharmacies: Pharmacy[];

  private inspectorColDefs = [
    {
      headerName: "Inspection #",
      field: "id",
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
    },
    {
      headerName: "Inspection Date",
      field: "inspection_date",
      suppressSizeToFit: false,
      width: 160,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleDateString("en-GB", {
              timeZone: "UTC",
            })
          : "";
      },
    },
    {
      headerName: "Pharmacy Name",
      field: "pharmacy_id",
      valueGetter: this.pharmacyFormatter,
      width: 250,
    },
    {
      headerName: "Document Version",
      field: "document_id",
      valueGetter: this.documentFormatter,
      width: 250,
    },
    {
      headerName: "Created by",
      field: "created_by",
      width: 160,
      suppressSizeToFit: true,
    },
    {
      headerName: "Created at",
      field: "created_at",
      width: 215,
      suppressSizeToFit: true,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleString("en-GB", { timeZone: "UTC" })
          : "";
      },
    },
    {
      headerName: "Updated by",
      field: "updated_by",
      width: 160,
      suppressSizeToFit: true,
    },
    {
      headerName: "Updated at",
      field: "updated_at",
      width: 215,
      suppressSizeToFit: true,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleString("en-GB", { timeZone: "UTC" })
          : "";
      },
    },
  ];

  private adminManagerColDefs = [
    {
      headerName: "Inspection #",
      field: "id",
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
    },
    {
      headerName: "Inspector Name",
      field: "inspector_id",
      valueGetter: this.inspectorFormatter,
      width: 160,
    },
    {
      headerName: "Inspection Date",
      field: "inspection_date",
      suppressSizeToFit: false,
      width: 160,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleDateString("en-GB", {
              timeZone: "UTC",
            })
          : "";
      },
    },
    {
      headerName: "Pharmacy Name",
      field: "pharmacy_id",
      valueGetter: this.pharmacyFormatter,
      width: 250,
    },
    {
      headerName: "Document Version",
      field: "document_id",
      valueGetter: this.documentFormatter,
      width: 250,
    },
    {
      headerName: "Created by",
      field: "created_by",
      width: 160,
      suppressSizeToFit: true,
    },
    {
      headerName: "Created at",
      field: "created_at",
      width: 215,
      suppressSizeToFit: true,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleString("en-GB", { timeZone: "UTC" })
          : "";
      },
    },
    {
      headerName: "Updated by",
      field: "updated_by",
      width: 160,
      suppressSizeToFit: true,
    },
    {
      headerName: "Updated at",
      field: "updated_at",
      width: 215,
      suppressSizeToFit: true,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleString("en-GB", { timeZone: "UTC" })
          : "";
      },
    },
  ];

  myColDefs = this.isAdminOrManager
    ? this.adminManagerColDefs
    : this.inspectorColDefs;

  gridOptions = {
    // PROPERTIES - object properties, myRowData and myColDefs are created somewhere in your application
    rowData: this.myRowData,
    columnDefs: this.myColDefs,

    // PROPERTIES - simple boolean / string / number properties
    pagination: true,
    rowSelection: "single",

    gridApi: null,
    gridColumnApi: null,

    onGridReady: function (event) {
      this.gridApi = this.api;
      this.gridColumnApi = this.columnApi;
    },
    defaultColDef: {
      sortable: true,
      filter: true,
    },
  };

  constructor(
    private inspectionService: InspectionService,
    private pharmacyService: PharmacyService,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getCurrentUser().subscribe((user) => {
      this.isAdminOrManager =
        user[0].role === UserRole.ADMIN || user[0].role === UserRole.MANAGER;

      if (this.isAdminOrManager) {
        this.myRowData = this.inspectionService.getInspections();
        this.myColDefs = this.adminManagerColDefs;
      } else {
        this.myRowData = this.inspectionService.getInspectionsByInspector(
          user[0].inspector_id
        );
        this.myColDefs = this.inspectorColDefs;
      }
    });

    this.pharmacyService.getPharmacies().subscribe((pharmacies) => {
      this.pharmacies = pharmacies as Pharmacy[];
    });
  }

  inspectorFormatter(params) {
    return params.data.inspector.name;
  }

  pharmacyFormatter(params) {
    return params.data.pharmacy.pharmacyName;
  }

  documentFormatter(params) {
    return params.data.document.name;
  }

  onAddData() {
    this.router.navigate(["inspection/edit"]);
  }

  onUpdateData() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var id = selectedData[0].id;

    localStorage.setItem("editInspectionId", id.toString());

    this.router.navigate(["inspection/edit/" + id.toString()]);
  }

  onRemoveSelected() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var res = this.gridOptions.gridApi.updateRowData({ remove: selectedData });
    var id = res.remove[0].data.id;

    this.inspectionService.deleteInspection(id).subscribe(
      (res) => {
        this.dialogService.openDialog(
          "Pharmacy record deleted",
          JSON.stringify(res)
        );
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
        console.log(error);
      }
    );
  }

  public previousForm() {
    this.router.navigateByUrl("/inspection");
  }
}
