import { Component, OnInit } from "@angular/core";
import { Pharmacy } from "./pharmacy.model";
import { PharmacyService } from "../services/pharmacy.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Constants } from "../services/constant-service";
import { DialogService } from "../services/dialog.service";

@Component({
  selector: "app-pharmacy",
  templateUrl: "./pharmacy.component.html",
  styleUrls: ["./pharmacy.component.scss"],
})
export class PharmacyComponent implements OnInit {
  myRowData: Observable<Pharmacy[]> = null;
  model: Pharmacy;

  myColDefs = [
    {
      headerName: "Licence #",
      field: "licenseNumber",
      width: 130,
      suppressSizeToFit: true,
      checkboxSelection: true,
    },
    {
      headerName: "Name",
      field: "pharmacyName",
      suppressSizeToFit: false,
      width: 250,
    },
    {
      headerName: "Status",
      field: "status",
      valueFormatter: this.statusFormatter,
      suppressSizeToFit: true,
      width: 120,
    },
    { headerName: "Company Name", field: "companyName" },
    { headerName: "Street Address", field: "addressLine1", width: 250 },
    { headerName: "City", field: "city", width: 140 },
    { headerName: "Manager", field: "manager", width: 200 },
    { headerName: "Phone #1", field: "primaryPhone", width: 160 },
  ];

  gridOptions = {
    // PROPERTIES - object properties, myRowData and myColDefs are created somewhere in your application
    rowData: this.myRowData,
    columnDefs: this.myColDefs,

    // PROPERTIES - simple boolean / string / number properties
    pagination: true,
    rowSelection: "single",

    gridApi: null,
    gridColumnApi: null,

    onGridReady: function (event) {
      this.gridApi = this.api;
      this.gridColumnApi = this.columnApi;
    },
    defaultColDef: {
      sortable: true,
      filter: true,
    },
  };

  constructor(
    private pharmacyService: PharmacyService,
    private _router: Router,
    private dialogService: DialogService
  ) {}

  statusFormatter(params) {
    status;

    status = Constants.Status.find((x) => x.Id == params.value).Name;

    return status;
  }

  onRemoveSelected() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var res = this.gridOptions.gridApi.updateRowData({ remove: selectedData });
    var id = res.remove[0].data.id;

    this.pharmacyService.deletePharmacy(id).subscribe(
      (res) => {
        this.dialogService.openDialog(
          "Pharmacy record deleted",
          JSON.stringify(res)
        );
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
        console.log(error);
      }
    );
  }

  onUpdateData() {
    var selectedData = this.gridOptions.gridApi.getSelectedRows();
    var id = selectedData[0].id;

    localStorage.setItem("editPharmacyId", id.toString());

    this._router.navigate(["pharmacy/edit/" + id.toString()]);
  }

  onAddData() {
    this._router.navigate(["pharmacy/edit"]);
  }

  ngOnInit() {
    this.myRowData = this.pharmacyService.getPharmacies();
  }
}
