import { Injectable } from "@angular/core";
import { Document } from "../document/document.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const API_URL: string = environment.apiUrl + "/documents";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  handleError<T>(error) {
    let errorMessage: any[] = [];
    let errorDetail = "";
    let errorColumn: any;

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage[0] = `Error: ${error.error.message}`;
    } else {
      errorColumn = error.error;

      const mapped = Object.keys(errorColumn).map((key) => ({
        type: key,
        value: errorColumn[key],
      }));

      mapped.forEach((element) => {
        errorDetail = element.type + " " + element.value[0];
      });

      // server-side error
      errorMessage[0] = `${errorDetail}`;
      errorMessage[1] = `Error Code: ${error.status}\nMessage: ${error.message}\n`;
    }
    return throwError(errorMessage);
  }

  addInspectionDocument(path: string, body: any): Observable<Document> {
    const url = `${API_URL}/${path}`;

    return this.http
      .post<Document>(url, body)
      .pipe(catchError(this.handleError));
  }

  updateInspectionDocument(
    path: string,
    body: any,
    id: any
  ): Observable<Document> {
    const url = `${API_URL}/${id}`;

    return this.http
      .put<Document>(url, body)
      .pipe(catchError(this.handleError));
  }

  deleteInspectionDocument(id): Observable<Document> {
    const url = `${API_URL}/${id}`;

    return this.http
      .delete<Document>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  cloneInspectionDocument(id): Observable<Document> {
    const url = `${API_URL}/clone_record?id=${id}`;

    return this.http
      .get<Document>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getInspectionDocument(id): Observable<Document> {
    const url = `${API_URL}/${id}`;

    return this.http
      .get<Document>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getInspectionDocuments(): Observable<Document[]> {
    return this.http
      .get<Document[]>(API_URL)
      .pipe(catchError(this.handleError));
  }
}
