<p-card>
  <h4>In1Touch - Pharmacy</h4>
</p-card>

<p-card>
  <input
    type="file"
    #fileImportInput
    name="File Upload"
    id="txtFileUpload"
    (change)="fileChangeListener($event)"
    accept=".csv"
  />
</p-card>

<ag-grid-angular
  id="myGrid"
  style="width: 100%; height: 465px"
  class="ag-theme-material"
  [rowData]="myRowData"
  [columnDefs]="myColDefs"
  [paginationAutoPageSize]="true"
  [pagination]="true"
  [animateRows]="true"
>
</ag-grid-angular>

<div class="form-group">
  <button class="btn btn-success" (click)="importRecords()">
    <span class="glyphicon glyphicon-ok"></span> Import
  </button>
</div>
