import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../app.component';
import { AngularTokenService } from 'angular-token';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class AppTopBarComponent implements OnInit {

    constructor(
        public app: AppComponent,
        public tokenService: AngularTokenService,
        private router: Router ) {}

    ngOnInit() {
        this.tokenService.validateToken();
    }

    onSignout() {
        this.tokenService.signOut().subscribe(
            (x) => console.log('Successful logout...')
        );
        this.router.navigate(['login']);
    }
}
