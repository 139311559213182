<p-card>
  <h4>User</h4>
</p-card>

<div class="edit-user-form-container">
  <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            id="name"
            [maxlength]="200"
            [(ngModel)]="model.name"
            disabled="true"
            #name="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && name.invalid }"
          />
          <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
            <div *ngIf="name.errors.required">Name is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="name">Email</label>
          <input
            type="text"
            class="form-control"
            name="email"
            id="email"
            [maxlength]="200"
            [(ngModel)]="model.email"
            disabled="true"
            #email="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
          />
          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
            <div *ngIf="email.errors.required">Email is required</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="role">Role</label>
          <select
            id="role"
            class="hideLabel form-control"
            [(ngModel)]="model.role"
            name="role"
            required
            #role="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && role.invalid }"
          >
            <option [ngValue]="k.Id" *ngFor="let k of listRoles">
              {{ k.Name }}
            </option>
          </select>
          <div *ngIf="f.submitted && role.invalid" class="invalid-feedback">
            <div *ngIf="role.errors.required">Role is required</div>
          </div>
        </div>
      </div>

      <div *ngIf="model.role == '1'" class="col-md-3">
        <div class="form-group">
          <label for="name">Inspector</label>
          <select
            id="inspector_id"
            class="hideLabel form-control"
            [(ngModel)]="model.inspector_id"
            name="inspector_id"
            required
            #inspector_id="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && inspector_id.invalid }"
          >
            <option [ngValue]="k.id" *ngFor="let k of listInspectors">
              {{ k.name }}
            </option>
          </select>
          <div
            *ngIf="f.submitted && inspector_id.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="inspector_id.errors.required">
              Inspector is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="active">Active</label>
          <select
            id="active"
            class="hideLabel form-control"
            [(ngModel)]="model.active"
            name="active"
            required
            #active="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && active.invalid }"
          >
            <option [ngValue]="k.Id" *ngFor="let k of listActive">
              {{ k.Name }}
            </option>
          </select>
          <div *ngIf="f.submitted && active.invalid" class="invalid-feedback">
            <div *ngIf="active.errors.required">Active is required</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-success">
        <span class="glyphicon glyphicon-ok"></span> Save
      </button>
      <button class="btn btn-info" type="button" (click)="previousForm()">
        <span class="glyphicon glyphicon-chevron-left"></span> Back
      </button>
    </div>
  </form>
</div>
