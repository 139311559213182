import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { OauthComponent } from './oauth/oauth.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { ReportInspSummaryComponent } from './report-insp-summary/report-insp-summary.component';
import { PharmacyEditComponent } from './pharmacy/pharmacy-edit/pharmacy-edit.component';
import { In1touchPharmacyComponent } from './in1touch-pharmacy/in1touch-pharmacy.component';
import { InspectorComponent} from './inspector/inspector.component';
import { DocumentComponent } from './document/document.component' ;
import { InspectionDocumentEditComponent} from './document/document-edit/document-edit.component' ;
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GlobalSettingComponent } from './global-setting/global-setting.component';
import { InspectorEditComponent } from './inspector/inspector-edit/inspector-edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExportInspectionComponent } from './export-inspection/export-inspection.component';
import { UserComponent} from './user/user.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { InspectionComponent } from './inspection/inspection.component';
import { InspectionEditComponent } from './inspection/inspection-edit/inspection-edit.component';
import { RoleGuard } from './guards/role-guard.service';
import { UserRole } from './services/enumeration';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './guards/auth-guard';

const appRoutes: Routes = [
  { path: '',
    component: HomeComponent
  },
  { path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  { path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN]}
  },
  { path: 'user/edit',
    component: UserEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN]}
  },
  { path: 'user/edit/:id',
    component: UserEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN]}
  },
  {
    path: 'export_inspection',
    component: ExportInspectionComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'oauth-callback',
    component: OauthCallbackComponent
  },
  {
    path: 'login',
    component: OauthComponent
  },
  {
    path: 'global_setting',
    component: GlobalSettingComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {  path: 'pharmacy',
    component: PharmacyComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'pharmacy/add',
    component: PharmacyEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'pharmacy/edit',
    component: PharmacyEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'pharmacy/edit/:id',
    component: PharmacyEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'report_insp_summary',
    component: ReportInspSummaryComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'in1touch_pharmacy',
    component: In1touchPharmacyComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspector',
    component: InspectorComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspector/add',
    component: InspectorEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspector/edit',
    component: InspectorEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspector/edit/:id',
    component: InspectorEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspection-document',
    component: DocumentComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspection-document',
    component: DocumentComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER]}
  },
  {
    path: 'inspection',
    component: InspectionComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  {
    path: 'inspection/add',
    component: InspectionEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  {
    path: 'inspection/edit',
    component: InspectionEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  {
    path: 'inspection/edit/:id',
    component: InspectionEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  {
    path: 'inspection-document/add',
    component: InspectionDocumentEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  {
    path: 'inspection-document/edit',
    component: InspectionDocumentEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  {
    path: 'inspection-document/edit/:id',
    component: InspectionDocumentEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INSPECTOR]}
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
