<!-- <p-panelMenu class='menubar' [model]="items" [style]="{ 'top':'5'}"></p-panelMenu> -->

<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive, 'layout-sidebar-dark': app.darkMenu}"
    (click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
    <div class="sidebar-logo">
        <a routerLink="/dashboard">
            <img alt="logo" src="assets/layout/images/managers.png" />
            <span style="font-size: 13pt" class="app-name"> MANAGERS</span>
        </a>
        <a href="#" class="sidebar-anchor" title="Toggle Menu" (click)="app.onToggleMenuClick($event)"></a>
    </div>

    <p-scrollPanel #scrollPanel [style]="{height: '100%'}">
        <ul app-submenu [item]="model" root="true" class="layout-menu" visible="true" [reset]="reset" parentActive="true"></ul>
    </p-scrollPanel>

</div>


