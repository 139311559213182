import { Injectable } from '@angular/core';
import { User } from '../services/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, take, map } from 'rxjs/operators';
import { environment} from '../../environments/environment';
import { AngularTokenService } from 'angular-token';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

const API_URL: string = environment.apiUrl + '/users';

interface InspectorRed {
  name: string;
  id: number;
}

@Injectable({
    providedIn: 'root'
 })

export class UserService {

    constructor(private http: HttpClient, 
                private tokenService: AngularTokenService) {

    }

    handleError<T>(error) {
      const errorMessage: any[] = [];
      let errorDetail = '';
      let errorColumn: any;

      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage[0] = `Error: ${error.error.message}`;
      } else {

        errorColumn = error.error;

        const mapped = Object.keys(errorColumn).map(key => ({type: key, value: errorColumn[key]}));

        mapped.forEach( (element) => {
          errorDetail = element.type + ' ' + element.value[0];
      });

      // server-side error
      errorMessage[0] = `${errorDetail}`;
      errorMessage[1] = `Error Code: ${error.status}\nMessage: ${error.message}\n`;

      }
      return throwError(errorMessage);
    }

    updateUser (path: string, body: any, id: any): Observable<User> {
      const url = `${API_URL}/${id}`;

      return this.http.put <User>(url, body).pipe(
        catchError(this.handleError));
    }

    deleteInspector (id): Observable<User> {
        const url = `${API_URL}/${id}`;

        return this.http.delete<User>(url, httpOptions).pipe(
          catchError(this.handleError)
        );
      }

    getUser (id): Observable<User> {
      const url = `${API_URL}/${id}`;

      return this.http.get<User>(url, httpOptions).pipe(
        tap(_ => console.log(`loaded user id=${id}`)),
        catchError(this.handleError)
      );
    }

    getUserByUid (q: any): Observable<User> {
      const url = `${API_URL}/getUserByUid/?q=${q}`;
      return this.http.get<User>(url)
          .pipe(
          catchError(this.handleError)
          );
      }

    getCurrentUser(): Observable<User> {
      return this.getUserByUid(this.tokenService.currentAuthData.uid).pipe(take(1), map(user => user));
    }  

    getUsers (): Observable<User[]> {
    return this.http.get<User[]>(API_URL)
        .pipe(
        tap(_ => console.log('fetched users')),
        catchError(this.handleError)
        );
    }
}
