<div *ngIf="tokenService.userSignedIn()">
  <div
    class="layout-wrapper"
    (click)="onWrapperClick()"
    [ngClass]="{
      'layout-wrapper-static': layoutMode === 'static',
      'layout-wrapper-active': mobileMenuActive,
      'layout-menu-horizontal': layoutMode === 'horizontal',
      'layout-rtl': isRTL
    }"
  >
    <app-menu [reset]="resetMenu"></app-menu>

    <div class="layout-main">
      <app-topbar></app-topbar>

      <app-breadcrumb></app-breadcrumb>

      <div class="layout-content">
        <router-outlet></router-outlet>
      </div>

      <app-footer></app-footer>
      <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
    </div>
  </div>
</div>

<div *ngIf="!tokenService.userSignedIn()">
  <app-oauth></app-oauth>
</div>
