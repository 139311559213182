export default class MyUtils {
  public static isEmpty(strValue: string): boolean {
    // Test whether strValue is empty
    if (!strValue || strValue.trim() === "" || strValue.trim().length === 0) {
      return true;
    }

    return false;
  }
}
