<div class="layout-topbar">
  <a
    routerLink="/"
    *ngIf="app.isHorizontal() && !app.isMobile()"
    class="topbar-logo"
  >
    <span class="app-name">INSPECTIONS</span>
  </a>

  <!-- <img alt="logo" src="assets/layout/images/logo-slim.png" class="mobile-logo"/> -->

  <a href="#" class="menu-btn" (click)="app.onMenuButtonClick($event)">
    <i class="material-icons">&#xE5D2;</i>
  </a>

  <a
    href="#"
    class="topbar-menu-btn"
    (click)="app.onTopbarMobileMenuButtonClick($event)"
  >
    <i class="material-icons">&#xE853;</i>
  </a>

  <div class="layout-topbar-menu-wrapper">
    <ul
      class="topbar-menu fadeInDown"
      [ngClass]="{ 'topbar-menu-active': app.topbarMenuActive }"
      (click)="app.onTopbarMenuClick($event)"
    >
      <li
        #profile
        class="profile-item"
        [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === profile }"
        (click)="app.onTopbarRootItemClick($event, profile)"
      >
        <a href="#">
          <span class="profile-image-wrapper">
            <img
              src="{{ tokenService?.currentUserData?.image }}"
              alt="Avatar"
              class="avatar"
            />
          </span>
          <span class="topbar-item-name profile-name">{{
            tokenService?.currentUserData?.name
          }}</span>
        </a>

        <ul class="fadeInDown">
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">account_circle</i>
              <span>Profile</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">verified_user</i>
              <span>Privacy</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">settings_application</i>
              <span>Settings</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="onSignout()">
              <i class="material-icons">exit_to_app</i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        #settings
        [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === settings }"
        (click)="app.onTopbarRootItemClick($event, settings)"
      >
        <a href="#">
          <i class="topbar-icon material-icons">settings</i>
          <span class="topbar-item-name">Menu Modes</span>
        </a>
        <ul class="fadeInDown">
          <li role="menuitem" (click)="this.app.layoutMode = 'static'">
            <a href="#">
              <i class="material-icons">view_quilt</i>
              <span>Static Menu</span>
            </a>
          </li>
          <li role="menuitem" (click)="this.app.layoutMode = 'overlay'">
            <a href="#">
              <i class="material-icons">flip_to_front</i>
              <span>Overlay Menu</span>
            </a>
          </li>
          <li role="menuitem" (click)="this.app.layoutMode = 'horizontal'">
            <a href="#">
              <i class="material-icons">border_horizontal</i>
              <span>Horizontal Menu</span>
            </a>
          </li>
          <li role="menuitem" (click)="this.app.darkMenu = false">
            <a href="#">
              <i class="material-icons">label</i>
              <span>Light Menu</span>
            </a>
          </li>
          <li role="menuitem" (click)="this.app.darkMenu = true">
            <a href="#">
              <i class="material-icons">label_outline</i>
              <span>Dark Menu</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        #messages
        [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === messages }"
        (click)="app.onTopbarRootItemClick($event, messages)"
      >
        <!-- <a href="#">
                        <i class="topbar-icon material-icons animated swing">&#xE0C9;</i>
                        <span class="topbar-badge animated rubberBand">5</span>
                        <span class="topbar-item-name">Messages</span>
                    </a> -->
        <ul class="fadeInDown">
          <li role="menuitem">
            <a href="#" class="topbar-message">
              <img src="assets/layout/images/avatar1.png" width="35" />
              <span>Give me a call</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message">
              <img src="assets/layout/images/avatar2.png" width="35" />
              <span>Sales reports attached</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message">
              <img src="assets/layout/images/avatar3.png" width="35" />
              <span>About your invoice</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message">
              <img src="assets/layout/images/avatar2.png" width="35" />
              <span>Meeting today at 10pm</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message">
              <img src="assets/layout/images/avatar4.png" width="35" />
              <span>Out of office</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        #notifications
        [ngClass]="{
          'active-topmenuitem': app.activeTopbarItem === notifications
        }"
        (click)="app.onTopbarRootItemClick($event, notifications)"
      >
        <!-- <a href="#">
                        <i class="topbar-icon material-icons">notifications</i>
                        <span class="topbar-badge animated rubberBand">4</span>
                        <span class="topbar-item-name">Notifications</span>
                    </a> -->
        <ul class="fadeInDown">
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">bug_report</i>
              <span>Pending tasks</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">event</i>
              <span>Meeting today at 3pm</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">cloud_download</i>
              <span>Download documents</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#">
              <i class="material-icons">flight</i>
              <span>Book flight</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
