import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Document } from "../document.model";
import { DocumentService } from "../../services/document.service";
import { DialogService } from "../../services/dialog.service";
import { TreeNode } from "primeng/api";
import MyUtils from "../../../utils/util";

interface ILegacyTreeViewItem {
  name: string;
  id: number;
  childrens: ILegacyTreeViewItem[];
}

@Component({
  selector: "app-document-edit",
  templateUrl: "./document-edit.component.html",
  styleUrls: ["./document-edit.component.scss"],
})
export class InspectionDocumentEditComponent implements OnInit {
  model = new Document();
  tableData: TreeNode[] = [];
  cols: any[];
  title: String;

  @ViewChild("documentPracticeStd") documentPracticeStd;

  constructor(
    private inspectionDocumentService: DocumentService,
    private router: Router,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.cols = [{ field: "name", header: "Name" }];
    this.title = "Professional Practice Audit";

    let inspectionDocumentID = localStorage.getItem("editInspectionDocumentId");

    if (!inspectionDocumentID) {
      this.model = new Document();
    } else {
      this.inspectionDocumentService
        .getInspectionDocument(inspectionDocumentID)
        .subscribe((data) => {
          this.model = data;

          if (data.structure && data.structure != "null") {
            this.myTree = JSON.parse(data.structure);

            // I don't know how to explain this yet
            this.tableData = [
              { data: { name: "element.name", id: "element.id" } },
            ];
            this.tableData.pop();

            this.loadTreeViewData(this.myTree);
          }
        });
    }

    this.cols = [{ field: "name", header: "Description" }];

    localStorage.clear();
  }

  rootNode: TreeNode;
  myTree = [];
  selectedNode;

  addNode() {
    if (this.selectedNode) {
      var newNode = {
        data: { name: "", id: Math.floor(Math.random() * 1000000 + 1) },
        children: [],
        expanded: true,
      };

      this.selectedNode.children.push(newNode);
      this.tableData = [...this.tableData];
      this.selectedNode.expanded = true;
      this.selectedNode = newNode;

      if (this.documentPracticeStd.value !== null) {
        let list = document.querySelectorAll("tr");

        let index = this.findIndexNestedObject(
          this.documentPracticeStd.value,
          newNode.data.id
        );

        if (list !== null && index < list.length) {
          let targetElement = list.item(index);
          if (targetElement !== null) {
            targetElement.scrollIntoView();
          }
        }
      }
    }
  }

  findIndexNestedObject(valueList: any, elementId: number): number {
    if (valueList !== null) {
      const result = [];
      valueList.map((obj) => {
        if (obj.children) {
          const el = { ...obj, ...{} };
          delete el.children;
          result.push(el);
          Object.values(obj.children).map((v, i) => {
            result.push(v);
          });
        } else {
          result.push(obj);
        }
      });

      return result.findIndex((i) => i.data.id === elementId);
    }
  }

  addRootNode() {
    this.tableData.push({
      data: { name: "", id: Math.random() },
      children: [],
      expanded: true,
    });
    this.tableData = [...this.tableData];
  }

  removeNode() {
    if (this.selectedNode.parent != null) {
      this.selectedNode.parent.children.splice(
        this.selectedNode.parent.children.indexOf(this.selectedNode),
        1
      );
      this.tableData = [...this.tableData];
    }
  }

  preventAddNode(): boolean {
    if (this.selectedNode != null && this.selectedNode.parent != null) {
      if (this.selectedNode.parent.parent != null) {
        return true;
      }
    }
    return false;
  }

  loadTreeViewData(legacyTreeView: ILegacyTreeViewItem[]) {
    legacyTreeView.forEach((legacyTreeViewItem) => {
      this.rootNode = this.createTreeNodeItem(legacyTreeViewItem);

      this.loadTreeViewDataChildren(
        legacyTreeViewItem.childrens,
        this.rootNode.children
      );

      this.tableData.push(this.rootNode);
    });
  }

  loadTreeViewDataChildren(
    legacyTreeView: ILegacyTreeViewItem[],
    childNode: TreeNode[] = null
  ) {
    legacyTreeView.forEach((legacyTreeViewItem) => {
      childNode.push(this.createTreeNodeItem(legacyTreeViewItem));

      this.loadTreeViewDataChildren(
        legacyTreeViewItem.childrens,
        childNode[childNode.length - 1].children
      );
    });
  }

  createTreeNodeItem(argTreeViewItem: ILegacyTreeViewItem) {
    return {
      data: { name: argTreeViewItem.name, id: argTreeViewItem.id },
      children: [],
    };
  }

  createLegacyTreeNodeItem(argTreeViewItem: ILegacyTreeViewItem) {
    return {
      data: { name: argTreeViewItem.name, id: argTreeViewItem.id },
      children: [],
    };
  }

  public previousForm() {
    this.router.navigateByUrl("/inspection-document");
  }

  validateDocumentStructure(array: ILegacyTreeViewItem[]): boolean {
    if (this.findEmptyName(array)) {
      this.dialogService.openDialog(
        "There is a section with empty description. Please review the document entries.",
        ""
      );
      return false;
    }

    return true;
  }

  findEmptyName(treeViewItems: ILegacyTreeViewItem[]): boolean {
    for (const node of treeViewItems) {
      if (MyUtils.isEmpty(node.name)) {
        return true;
      }

      if (node.childrens.length > 0) {
        if (this.findEmptyName(node.childrens)) {
          return true;
        }
      }
    }

    return false;
  }

  private convertArrayToLegacy(
    treeViewData: TreeNode[]
  ): Array<ILegacyTreeViewItem> {
    var legacyItem: ILegacyTreeViewItem[] = [];

    treeViewData.forEach((element) => {
      if (element.children) {
        legacyItem.push({
          id: element.data.id,
          name: element.data.name,
          childrens: this.convertArrayToLegacy(element.children),
        });
      } else {
        legacyItem.push({
          id: element.data.id,
          name: element.data.name,
          childrens: null,
        });
      }
    });

    return legacyItem;
  }

  public onSubmit() {
    this.myTree = this.convertArrayToLegacy(this.tableData);

    if (!this.validateDocumentStructure(this.myTree)) {
      return;
    }

    this.model.structure = JSON.stringify(this.myTree);

    if (this.model.id) {
      this.inspectionDocumentService
        .updateInspectionDocument("", this.model, this.model.id)
        .subscribe(
          (res) => {
            this.dialogService.openDialog(
              "Inspection document record updated",
              JSON.stringify(res)
            );
          },
          (error) => {
            console.log(error);

            this.dialogService.openDialogError(error[0], error[1]);
          },
          () => {
            this.model = new Document();
            this.previousForm();
          }
        );
    } else {
      this.inspectionDocumentService
        .addInspectionDocument("", this.model)
        .subscribe(
          (res) => {
            this.dialogService.openDialog(
              "Inspection document record created",
              JSON.stringify(res)
            );
          },
          (error) => {
            this.dialogService.openDialogError(error[0], error[1]);
          },
          () => {
            this.model = new Document();
            this.previousForm();
          }
        );
    }
  }
}
function myFlat(children: any, path: any): any {
  throw new Error("Function not implemented.");
}
