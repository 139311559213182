import { Component, OnInit } from '@angular/core';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {

  constructor(private tokenService: AngularTokenService) { }

  ngOnInit() {
  }

  onSignIn() {
    this.tokenService.signInOAuth('google_oauth2');
  }

}
