import { Component, OnInit, OnDestroy } from "@angular/core";
import { PharmacyService } from "../services/pharmacy.service";
import { InspectionService } from "../services/inspection.service";
import { InspectorService } from "../services/inspector.service";
import { take, map, takeUntil } from "rxjs/operators";
import { forkJoin, Subject } from "rxjs";
import { AngularTokenService } from "angular-token";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [InspectorService],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public qtyOpenPharmacies: any;
  public qtyInspections: any;
  public qtyInspectionsByMonth: any;
  public lastInspectionDate: any;
  public inspectors: any[];
  public chartData: any;

  public listInspectorsStats: {
    numberOfInspections: number;
    PercentualOfInspections: number;
  }[] = [];

  constructor(
    private pharmacyService: PharmacyService,
    private inspectionService: InspectionService,
    private inspectorService: InspectorService,
    private tokenService: AngularTokenService
  ) {}

  ngOnInit() {
    if (this.tokenService.userSignedIn()) {
      forkJoin(
        this.pharmacyService.getCountOpenedPharmacies(),
        this.inspectionService.getCountCurrentYear(),
        this.inspectionService.getCountCurrentMonth(),
        this.inspectionService.getLastInspectionDate(),
        this.inspectorService.getChartData()
      )
        .pipe(
          takeUntil(this.unsubscribe$),
          map(
            ([
              openedPharmacies,
              countCurrentYear,
              countCurrentMonth,
              lastInspectionDate,
              chartData,
            ]) => {
              return {
                openedPharmacies,
                countCurrentYear,
                countCurrentMonth,
                lastInspectionDate,
                chartData,
              };
            }
          )
        )
        .subscribe((res) => {
          this.inspectors = res.chartData;

          this.chartData = {
            labels: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            datasets: res.chartData,
          };

          this.qtyOpenPharmacies = res.openedPharmacies;
          this.qtyInspections = res.countCurrentYear;
          this.qtyInspectionsByMonth = res.countCurrentMonth;
          this.lastInspectionDate = res.lastInspectionDate
            ? res.lastInspectionDate
            : "--/--/----";
        });
    }
  }
}
