export const enum UserRole {
  NOT_ASSIGNED = 0,
  INSPECTOR = 1,
  MANAGER = 2,
  ADMIN = 99,
}

export enum Active {
  YES = 1,
  NO = 0,
}
