import { Component, Inject } from "@angular/core";
import { DialogService } from "../services/dialog.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
export interface DialogData {
  header: string;
  data: string;
  message: string;
}

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent {
  constructor(
    public dialogService: DialogService,
    public config: DynamicDialogConfig
  ) {}
}
