<p-card>
  <h4>Inspection</h4>
</p-card>

<div class="edit-inspection-form-container">
  <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="inspectionDate">Inspection Date</label>

          <input
            type="text"
            placeholder="Inform a date"
            class="form-control"
            name="inspection_date"
            id="inspection_date"
            required
            bsDatepicker
            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
            [(ngModel)]="model.inspection_date"
            #inspection_date="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && inspection_date.invalid }"
            placement="right"
          />

          <div
            *ngIf="f.submitted && inspection_date.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="inspection_date.errors.required">Date is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="date">Document</label>
          <select
            id="document_id"
            #Id="ngModel"
            class="hideLabel form-control"
            [(ngModel)]="model.document_id"
            name="document_id"
            required
            disabled="true"
            #document_id="ngModel"
          >
            <option [ngValue]="k.id" *ngFor="let k of listDocuments">
              {{ k.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="pharmacy_id">Pharmacy</label>
          <select
            type="text"
            placeholder="Inform the pharmacy name"
            class="form-control"
            name="pharmacy_id"
            id="pharmacy_id"
            required
            [(ngModel)]="model.pharmacy_id"
            #pharmacy_id="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && pharmacy_id.invalid }"
          >
            <option [ngValue]="k.id" *ngFor="let k of listPharmacies">
              {{
                k.pharmacyName +
                  " - " +
                  k.licenseNumber +
                  " - " +
                  k.addressLine1
              }}
            </option>
          </select>

          <div
            *ngIf="f.submitted && pharmacy_id.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="pharmacy_id.errors.required">
              Pharmacy Name is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isInspector" class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="inspector_id">Inspector</label>
          <select
            type="text"
            placeholder="Inform the inspector name"
            class="form-control"
            name="inspector_id"
            id="inspector_id"
            required
            [(ngModel)]="model.inspector_id"
            #inspector_id="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && inspector_id.invalid }"
          >
            <option [ngValue]="k.id" *ngFor="let k of listInspectors">
              {{ k.name }}
            </option>
          </select>

          <div
            *ngIf="f.submitted && inspector_id.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="inspector_id.errors.required">
              Inspector Name is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="childComponent">
      <app-inspection-document
        [id_inspection]="id_inspection"
      ></app-inspection-document>
    </div>

    <div class="form-group">
      <button class="btn btn-success">
        <span class="glyphicon glyphicon-ok"></span> Save
      </button>
      <button class="btn btn-info" type="button" (click)="previousForm()">
        <span class="glyphicon glyphicon-chevron-left"></span> Back
      </button>
    </div>
  </form>
</div>
