import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../environments/environment';

@Component({
  selector: 'app-report-insp-summary',
  templateUrl: './report-insp-summary.component.html',
  styleUrls: ['./report-insp-summary.component.scss']
})


export class ReportInspSummaryComponent implements OnInit {

  rowData: any;
  data: any;
  data2: any;
  data3: any;
  data4: any;
  options: any;

  columnDefs = [
    {headerName: 'License #', field: 'licenseNumber', width: 95, suppressSizeToFit: true, checkboxSelection: true },
    {headerName: 'Name', field: 'pharmacyName',suppressSizeToFit: true},
    {headerName: 'Company Name', field: 'companyName'},
    {headerName: 'Address', field: 'addressLine1'},
    {headerName: 'City', field: 'city', width: 120  },
    {headerName: 'Province', field: 'province', width: 90},
    {headerName: 'Country', field: 'country', width: 90},
    {headerName: 'PostalCode', field: 'postalCode', width: 120},
    {headerName: 'Phone #1', field: 'primaryPhone', width: 120},
    {headerName: 'Fax', field: 'fax', width: 120},
    {headerName: 'Email', field: 'email', width: 250},
    {headerName: 'Status', field: 'status', width: 80},
    {headerName: 'ID', field: 'id', checkboxSelection: true , width: 80, suppressSizeToFit: true}
];

  constructor(private http: HttpClient) {     


  }

  ngOnInit() {

    this.options = {
      responsive: false,
      maintainAspectRatio: false
    };

    this.rowData = this.http.get(environment.apiUrl + '/report_insp_summaries.json');

    
    this.data = {
      labels: ['1. Standard', '2. Staff', '3. Standards of Practice'],
      datasets: [
          {
              label: 'A. Wholesalers',
              backgroundColor: '#42A5F5',
              // borderColor: '#1E88E5',
              data: [65, 59, 90]
          },
          {
              label: 'Lawtons',
              backgroundColor: '#031138',
              // borderColor: '#7CB342',
              data: [28, 48, 40]
          },
          {
              label: 'Loblaw',
              backgroundColor: '#42A5F5',
              // borderColor: 'rgba(188, 3, 244, 0.397)',
              data: [80, 30, 10]
          },
          {
              label: 'Independent',
              backgroundColor: '#031138',
              // borderColor: '#f4e403',
              data: [55, 35, 15]
          },
          {
              label: 'Pharmasave',
              backgroundColor: '#42A5F5',
              // borderColor: '#f40303',
              data: [65, 20, 5]
          },
          {
              label: 'Walmart',
              backgroundColor: '#031138',
              // borderColor: '#f40303',
              data: [65, 20, 5]
          }
      ]
  };

  this.data2 = {
    labels: ['S.1.1', 'S.1.2', 'S.1.3', 'S.1.5', 'S.1.7'],
    datasets: [
        {
            label: 'A. Wholesalers',
            backgroundColor: '#42A5F5',
            // borderColor: '#1E88E5',
            data: [65, 59, 80, 10, 12]
        },
        {
            label: 'Lawtons',
            backgroundColor: '#031138',
            // borderColor: '#7CB342',
            data: [28, 48, 95, 43, 80]
        },
        {
            label: 'Loblaw',
            backgroundColor: '#42A5F5',
            // borderColor: 'rgba(188, 3, 244, 0.397)',
            data: [80, 30, 10, 89, 90]
        },
        {
            label: 'Independent',
            backgroundColor: '#031138',
            // borderColor: '#f4e403',
            data: [55, 35, 15, 34, 44]
        },
        {
            label: 'Pharmasave',
            backgroundColor: '#42A5F5',
            // borderColor: '#f40303',
            data: [65, 20, 5, 10, 45]
        }
    ]
};

this.data3 = {
  labels: ['S.2.8', 'S.2.9'],
  datasets: [
      {
          label: 'A. Wholesalers',
          backgroundColor: '#42A5F5',
          // borderColor: '#1E88E5',
          data: [65, 59]
      },
      {
          label: 'Lawtons',
          backgroundColor: '#031138',
          // borderColor: '#7CB342',
          data: [28, 99]
      },
      {
          label: 'Loblaw',
          backgroundColor: '#42A5F5',
          // borderColor: 'rgba(188, 3, 244, 0.397)',
          data: [80, 30]
      },
      {
          label: 'Independent',
          backgroundColor: '#031138',
          // borderColor: '#f4e403',
          data: [55, 35]
      },
      {
          label: 'Pharmasave',
          backgroundColor: '#42A5F5',
          // borderColor: '#f40303',
          data: [65, 20]
      }
  ]
};

this.data4 = {
  labels: ['S.3.10', 'S.3.11', 'S.3.12', 'S.3.13', 'S.3.14'],
  datasets: [
      {
          label: 'A. Wholesalers',
          backgroundColor: '#42A5F5',
          // borderColor: '#1E88E5',
          data: [65, 59, 80, 10, 12]
      },
      {
          label: 'Lawtons',
          backgroundColor: '#031138',
          // borderColor: '#7CB342',
          data: [28, 48, 40, 43, 80]
      },
      {
          label: 'Loblaw',
          backgroundColor: '#42A5F5',
          // borderColor: 'rgba(188, 3, 244, 0.397)',
          data: [80, 30, 10, 89, 90]
      },
      {
          label: 'Independent',
          backgroundColor: '#031138',
          // borderColor: '#f4e403',
          data: [55, 35, 15, 34, 44]
      },
      {
          label: 'Pharmasave',
          backgroundColor: '#42A5F5',
          // borderColor: '#f40303',
          data: [65, 20, 5, 10, 45]
      }
  ]
};
 
  };

}
