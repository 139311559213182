export class User {
    public id: number ;
    public provider: string;
    public uid: string;
    public name: string;
    public email: string;
    public image: string;
    public role: string;
    public sign_in_count: string;
    public active: number;
}
