<p-card>
  <h4>Dashboard</h4>
</p-card>

<div class="ui-g dashboard">
  <div class="dashboard-top-bar">
    <div class="dashboard-top-bar-item">
      <div class="overview-box overview-box-1">
        <div class="overview-box-header"></div>
        <div class="overview-box-content">
          <div class="overview-box-icon">
            <i class="material-icons">store</i>
          </div>
          <div class="overview-box-title">Pharmacies</div>
          <div class="overview-box-count">{{ qtyOpenPharmacies }}</div>
        </div>
      </div>
    </div>
    <div class="dashboard-top-bar-item">
      <div class="overview-box overview-box-2">
        <div class="overview-box-header"></div>
        <div class="overview-box-content">
          <div class="overview-box-icon">
            <i class="material-icons">date_range</i>
          </div>
          <div class="overview-box-title">Inspections - Current Year</div>
          <div class="overview-box-count">{{ qtyInspections }}</div>
        </div>
      </div>
    </div>
    <div class="dashboard-top-bar-item">
      <div class="overview-box overview-box-3">
        <div class="overview-box-header"></div>
        <div class="overview-box-content">
          <div class="overview-box-icon">
            <i class="material-icons">today</i>
          </div>
          <div class="overview-box-title">Inspections - Current Month</div>
          <div class="overview-box-count">{{ qtyInspectionsByMonth }}</div>
        </div>
      </div>
    </div>
    <div class="dashboard-top-bar-item">
      <div class="overview-box overview-box-4">
        <div class="overview-box-header"></div>
        <div class="overview-box-content">
          <div class="overview-box-icon">
            <i class="material-icons">restore</i>
          </div>
          <div class="overview-box-title">Last Inspection</div>
          <div class="overview-box-count">{{ lastInspectionDate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard-bottom-bar">
    <div *ngFor="let i of inspectors | slice : 0 : 2; let k = index">
      <div class="user-card card">
        <div class="user-card-header">
          <img
            src="assets/layout/images/dashboard/usercard.png"
            library="serenity-layout"
            width="100"
          />
        </div>
        <div class="user-card-content">
          <div class="user-card-name">
            <span>{{ i.label }}</span>
          </div>

          <div class="user-detail">
            <ul>
              <li class="clearfix">
                <i class="material-icons">assignment</i>
                <span class="project-title">Inspections</span>
                <span class="project-detail">{{ i.numInspections }}</span>
                <div class="project-progressbar">
                  <div
                    class="project-progressbar-value"
                    [style.width.%]="i.numInspections"
                  ></div>
                </div>
              </li>
              <li class="clearfix">
                <i class="material-icons">insert_chart</i>
                <span class="project-title">Percentual</span>
                <span class="project-detail"
                  >{{
                    (i.numInspections / qtyInspections) * 100
                      | number : "1.2-2"
                  }}%</span
                >
                <div class="project-progressbar">
                  <div
                    class="project-progressbar-value"
                    [style.width.%]="(i.numInspections / qtyInspections) * 100"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="user-card card">
      <div class="user-card-header">
        <img
          src="assets/layout/images/dashboard/usercard.png"
          library="serenity-layout"
          width="100"
        />
      </div>
      <div class="user-card-content">
        <div class="user-card-name">
          <span>Inspections Graph</span>
        </div>
        <div style="overflow: auto; margin: 0.5em">
          <p-chart type="line" [data]="chartData" responsive="true"></p-chart>
        </div>
      </div>
    </div>
  </div>
</div>
