<p-card>
  <h4>Export - Inspection</h4>
</p-card>

<div class="edit-pharmacy-form-container">
  <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="col col-lg-2">
      <div class="row">
        <label>Inspector</label>
      </div>

      <div class="row">
        <select class="selectpicker" multiple>
          <option
            value="{{ inspector.value.id }}"
            *ngFor="let inspector of inspectorOptions; let i = index"
          >
            {{ inspector.label }}
          </option>
        </select>
      </div>

      <div class="row">
        <p></p>
      </div>

      <div class="row">
        <label>Date Range</label>
      </div>

      <div class="row">
        <input
          type="text"
          name="daterange"
          id="daterange"
          class="form-control"
          placeholder="Inform the date period"
          placement="right"
          bsDaterangepicker
          [(ngModel)]="daterangepickerModel"
          required
          #daterange="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && daterange.invalid }"
        />

        <div *ngIf="f.submitted && daterange.invalid" class="invalid-feedback">
          <div *ngIf="daterange.errors.required">Date period is required</div>
        </div>
      </div>
    </div>

    <button class="btn btn-success">
      <span class="glyphicon glyphicon-save-file"></span> Export
    </button>
  </form>
</div>
