import { Injectable } from "@angular/core";
import { Pharmacy } from "../pharmacy/pharmacy.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const API_URL: string = environment.apiUrl + "/pharmacies";

@Injectable({
  providedIn: "root",
})
export class PharmacyService {
  constructor(private http: HttpClient) {}

  handleError<T>(error) {
    let errorMessage: any[] = [];
    let errorDetail = "";
    let errorColumn: any;

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage[0] = `Error: ${error.error.message}`;
    } else {
      errorColumn = error.error;

      const mapped = Object.keys(errorColumn).map((key) => ({
        type: key,
        value: errorColumn[key],
      }));

      mapped.forEach((element) => {
        errorDetail = element.type + " " + element.value[0];
      });

      // server-side error
      errorMessage[0] = `${errorDetail}`;
      errorMessage[1] = `Error Code: ${error.status}\nMessage: ${error.message}\n`;
    }
    return throwError(errorMessage);
  }

  addPharmacy(path: string, body: any): Observable<Pharmacy> {
    const url = `${API_URL}/${path}`;

    return this.http
      .post<Pharmacy>(url, body)
      .pipe(catchError(this.handleError));
  }

  updatePharmacy(path: string, body: any, id: any): Observable<Pharmacy> {
    const url = `${API_URL}/${id}`;

    return this.http
      .put<Pharmacy>(url, body)
      .pipe(catchError(this.handleError));
  }

  deletePharmacy(id): Observable<Pharmacy> {
    const url = `${API_URL}/${id}`;

    return this.http
      .delete<Pharmacy>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPharmacy(id: string, query?: string): Observable<Pharmacy> {
    let url = `${API_URL}/${id}`;
    if (query) {
      url = url + `/${query}`;
    }

    return this.http
      .get<Pharmacy>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getCountOpenedPharmacies(): Observable<BigInteger> {
    const url = `${API_URL}/getCountOpened`;

    return this.http
      .get<BigInteger>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPharmacies(): Observable<Pharmacy[]> {
    return this.http
      .get<Pharmacy[]>(API_URL)
      .pipe(catchError(this.handleError));
  }
}
