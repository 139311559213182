<p-card>
  <h4>Pharmacy</h4>
</p-card>

<div class="edit-pharmacy-form-container">
  <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
      <div class="col-md-1">
        <div class="form-group">
          <label for="licenseNumber">Licence #</label>
          <input
            type="text"
            class="form-control"
            name="licenseNumber"
            id="licenseNumber"
            [maxlength]="7"
            [(ngModel)]="model.licenseNumber"
            OnlyNumberDirective="true"
            required
            #licenseNumber="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && licenseNumber.invalid }"
          />
          <div
            *ngIf="f.submitted && licenseNumber.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="licenseNumber.errors.required">
              License Number is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="date">Pharmacy Name</label>
          <input
            type="text"
            name="pharmacyName"
            class="form-control"
            id="pharmacyName"
            ng-minlength="3"
            ng-maxlength="100"
            placeholder="Inform the pharmacy name"
            required
            #pharmacyName="ngModel"
            [(ngModel)]="model.pharmacyName"
            [ngClass]="{ 'is-invalid': f.submitted && pharmacyName.invalid }"
          />

          <div
            *ngIf="f.submitted && pharmacyName.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="pharmacyName.errors.required">
              Pharmacy Name is required
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="companyName">Company Name</label>
          <select
            id="companyName"
            #Id="ngModel"
            class="hideLabel form-control"
            [(ngModel)]="model.companyName"
            name="companyName"
            required
            #companyName="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && companyName.invalid }"
          >
            <option [ngValue]="k.Id" *ngFor="let k of listCompanies">
              {{ k.Name }}
            </option>
          </select>
          <div
            *ngIf="f.submitted && companyName.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="companyName.errors.required">
              Company Name is required
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <label for="status">Status</label>
        <select
          id="status"
          #Id="ngModel"
          class="hideLabel form-control"
          [(ngModel)]="model.status"
          name="status"
          required
          #status="ngModel"
        >
          <div *ngIf="f.submitted && status.invalid" class="invalid-feedback">
            <div *ngIf="status.errors.required">Status is required</div>
          </div>
          <option [ngValue]="k.Id" *ngFor="let k of listStatus">
            {{ k.Name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="pharmacyId">Address Line 1</label>
          <input
            type="text"
            class="form-control"
            name="addressLine1"
            id="addressLine1"
            [(ngModel)]="model.addressLine1"
            placeholder="Inform street address"
            required
            #addressLine1="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && addressLine1.invalid }"
          />
          <div
            *ngIf="f.submitted && addressLine1.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="addressLine1.errors.required">
              Address Line 1 is required
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="pharmacyId">Address Line 2</label>
          <input
            type="text"
            class="form-control"
            name="addressLine2"
            id="addressLine2"
            [(ngModel)]="model.addressLine2"
            placeholder="Inform unit number"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">Postal Code</label>
          <input
            type="text"
            postalCodeMask
            class="form-control"
            name="postalCode"
            id="postalCode"
            [maxlength]="7"
            [(ngModel)]="model.postalCode"
            oninput="this.value = this.value.toUpperCase()"
            required
            #postalCode="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && postalCode.invalid }"
          />

          <div
            *ngIf="f.submitted && postalCode.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="postalCode.errors.required">
              Postal Code is required
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">City</label>
          <select
            id="Id"
            #Id="ngModel"
            class="hideLabel form-control"
            [(ngModel)]="model.city"
            name="city"
            value="Halifax"
            required
            #city="ngModel"
          >
            <div *ngIf="f.submitted && city.invalid" class="invalid-feedback">
              <div *ngIf="city.errors.required">City is required</div>
            </div>

            <option [ngValue]="k.Id" *ngFor="let k of listCitiesOrdered">
              {{ k.Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">Province</label>
          <select
            id="Id"
            #Id="ngModel"
            class="hideLabel form-control"
            [(ngModel)]="model.province"
            name="province"
            disabled="true"
            value="NS"
            required
            #province="ngModel"
          >
            <option [ngValue]="k.Id" *ngFor="let k of listProvinces">
              {{ k.Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">Country</label>

          <select
            id="Id"
            #Id="ngModel"
            class="hideLabel form-control"
            [(ngModel)]="model.country"
            name="country"
            disabled="true"
            value="CA"
            required
            #country="ngModel"
          >
            <option [ngValue]="k.Id" *ngFor="let k of listCountries">
              {{ k.Name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="manager">Manager</label>
          <input
            type="text"
            class="form-control"
            name="manager"
            id="manager"
            [(ngModel)]="model.manager"
            required
            #manager="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && manager.invalid }"
          />
          <div *ngIf="f.submitted && manager.invalid" class="invalid-feedback">
            <div *ngIf="manager.errors.required">Manager Name is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="alternateManager">Alternate Manager</label>
          <input
            type="text"
            class="form-control"
            name="alternateManager"
            id="alternateManager"
            [(ngModel)]="model.alternateManager"
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">Phone Primary</label>
          <input
            type="text"
            appPhoneMask
            class="form-control"
            name="primaryPhone"
            id="primaryPhone"
            required
            [(ngModel)]="model.primaryPhone"
            #primaryPhone="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && primaryPhone.invalid }"
          />
          <div
            *ngIf="f.submitted && primaryPhone.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="primaryPhone.errors.required">
              Primary Phone Name is required
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">Phone Secondary</label>
          <input
            type="text"
            appPhoneMask
            class="form-control"
            name="secondaryPhone"
            id="secondaryPhone"
            [(ngModel)]="model.secondaryPhone"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="fax">Fax</label>
          <input
            type="text"
            appPhoneMask
            class="form-control"
            name="fax"
            id="fax"
            [(ngModel)]="model.fax"
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="pharmacyId">E-mail</label>
          <input
            type="email"
            class="form-control"
            name="email"
            id="email"
            [(ngModel)]="model.email"
            placeholder="info@example.com"
            required
            #email="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
          />
          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
            <div *ngIf="email.errors.required">Email address is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="website">Website</label>
          <input
            type="text"
            class="form-control"
            name="website"
            id="website"
            [(ngModel)]="model.website"
            placeholder="www.example.com"
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="inspector">Inspector</label>
          <select
            id="Id"
            #Id="ngModel"
            class="hideLabel form-control"
            [(ngModel)]="model.inspector_id"
            name="inspector"
            #inspector="ngModel"
          >
            <option [ngValue]="k.id" *ngFor="let k of listInspectors">
              {{ k.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-success">
        <span class="glyphicon glyphicon-ok"></span> Save
      </button>
      <button class="btn btn-info" type="button" (click)="previousForm()">
        <span class="glyphicon glyphicon-chevron-left"></span> Back
      </button>
    </div>
  </form>
</div>
