import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private userService: UserService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  
    return this.userService.getCurrentUser().pipe(map(res => {
    
      let roles = next.data.roles as Array<number>;
      let userAllowed = roles.find(x => x == res[0].role) > 0;

      if (userAllowed) {
        return true;
      } else {
        this._router.navigate(['/**']);
        return false;
      }
  })
 );
 }
}