import { Injectable } from '@angular/core';
import { Inspector } from '../services/inspector.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment} from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

const API_URL: string = environment.apiUrl + '/inspectors';

interface InspectorRed {
  name: string;
  id: number;
}

@Injectable({
    providedIn: 'root'
 })

export class InspectorService {

    constructor(private http: HttpClient) {

    }

    handleError<T>(error) {
      const errorMessage: any[] = [];
      let errorDetail = '';
      let errorColumn: any;

      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage[0] = `Error: ${error.error.message}`;
      } else {

        errorColumn = error.error;

        const mapped = Object.keys(errorColumn).map(key => ({type: key, value: errorColumn[key]}));

        mapped.forEach( (element) => {
          errorDetail = element.type + ' ' + element.value[0];
      });

      // server-side error
      errorMessage[0] = `${errorDetail}`;
      errorMessage[1] = `Error Code: ${error.status}\nMessage: ${error.message}\n`;

      }
      return throwError(errorMessage);
    }

    addInspector (path: string, body: any): Observable<Inspector> {
        const url = `${API_URL}/${path}`;

        return this.http.post <Inspector>(url, body).pipe(
          catchError(this.handleError)
        );
      }

    updateInspector (path: string, body: any, id: any): Observable<Inspector> {
      const url = `${API_URL}/${id}`;

      return this.http.put <Inspector>(url, body).pipe(
        catchError(this.handleError));
    }

    deleteInspector (id): Observable<Inspector> {
        const url = `${API_URL}/${id}`;

        return this.http.delete<Inspector>(url, httpOptions).pipe(
          catchError(this.handleError)
        );
      }

    getInspector (id): Observable<Inspector> {
      const url = `${API_URL}/${id}`;

      return this.http.get<Inspector>(url, httpOptions).pipe(
        tap(_ => console.log(`loaded inspector id=${id}`)),
        catchError(this.handleError)
      );
    }

    getInspectors (): Observable<Inspector[]> {
    return this.http.get<Inspector[]>(API_URL)
        .pipe(
        tap(_ => console.log('fetched inspectors')),
        catchError(this.handleError)
        );
    }

    export (q: any, r: any, inspector: InspectorRed[]): Observable<any[]> {

      let s_array = '';

      inspector.forEach(function (value) {
        s_array = s_array + `&s[]=${value}`;
      });

      const url = `${API_URL}/export/?q=${q}&r=${r}${s_array}`;

      return this.http.get<any[]>(url, httpOptions).pipe(
        tap(_ => console.log(`loaded records to be exported`)),
        catchError(this.handleError)
      );
    }

    getChartData (): Observable<any[]> {
      const url = `${API_URL}/getChartData`;

      return this.http.get<any[]>(url, httpOptions).pipe(
        catchError(this.handleError)
      );
    }

}
