import { Component, OnInit } from "@angular/core";
import { Inspector } from "../../services/inspector.model";
import { InspectorService } from "../../services/inspector.service";
import { DialogService } from "../../services/dialog.service";
import { Router } from "@angular/router";
import { Constants } from "../../services/constant-service";
import { OrderPipe } from "ngx-order-pipe";

@Component({
  selector: "app-inspector-edit",
  templateUrl: "./inspector-edit.component.html",
  styleUrls: ["./inspector-edit.component.scss"],
})
export class InspectorEditComponent implements OnInit {
  model = new Inspector();
  listCountries: any;
  listProvinces: any;
  listCities: any;
  listCitiesOrdered: any[];

  constructor(
    private router: Router,
    private inspectorService: InspectorService,
    private dialogService: DialogService,
    private orderPipe: OrderPipe
  ) {}

  ngOnInit() {
    let inspectorID = localStorage.getItem("editInspectorId");

    if (!inspectorID) {
      this.model = new Inspector();
      this.model.country = "CA";
      this.model.province = "NS";
      this.model.active = 1;
      this.model.city = "Halifax";
    } else {
      this.inspectorService.getInspector(inspectorID).subscribe((data) => {
        this.model = data;
      });
    }

    localStorage.clear();

    this.listCountries = Constants.Countries;
    this.listProvinces = Constants.Provinces;
    this.listCities = Constants.Cities;
    this.listCitiesOrdered = this.orderPipe.transform(this.listCities, "Name");
  }

  public previousForm() {
    this.router.navigateByUrl("/inspector");
  }

  public onSubmit() {
    if (this.model.id) {
      this.inspectorService
        .updateInspector("", this.model, this.model.id)
        .subscribe(
          (res) => {
            this.dialogService.openDialog(
              "Inspector record updated",
              JSON.stringify(res)
            );
          },
          (error) => {
            console.log(error);

            this.dialogService.openDialogError(error[0], error[1]);
          },
          () => {
            this.model = new Inspector();
            this.previousForm();
          }
        );
    } else {
      this.inspectorService.addInspector("", this.model).subscribe(
        (res) => {
          this.dialogService.openDialog(
            "Inspector record created",
            JSON.stringify(res)
          );
        },
        (error) => {
          this.dialogService.openDialogError(error[0], error[1]);
        },
        () => {
          this.model = new Inspector();
          this.previousForm();
        }
      );
    }
  }
}
