import { Component, OnInit } from "@angular/core";
import { AngularTokenService } from "angular-token";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { DialogService } from "../services/dialog.service";
import { UserRole, Active } from "../services/enumeration";
import { throwError } from "rxjs";

@Component({
  templateUrl: "./oauth-callback.component.html",
})
export class OauthCallbackComponent implements OnInit {
  constructor(
    private tokenService: AngularTokenService,
    private router: Router,
    private userService: UserService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.tokenService.processOAuthCallback();

    this.doProcessOauthCallback().then(
      () => this.router.navigate(["/"]),
      () => console.log("No access")
    );
  }

  doProcessOauthCallback() {
    var promise = new Promise<void>((resolve, reject) => {
      this.userService
        .getUserByUid(this.tokenService.currentAuthData.uid)
        .subscribe(
          (res) => {
            if (
              res[0].role === UserRole.NOT_ASSIGNED ||
              res[0].active !== Active.YES
            ) {
              console.log("No access");

              this.tokenService
                .signOut()
                .subscribe((x) => console.log("Successful logout..."));

              this.dialogService.openDialog(
                `Hi ${res[0].name}. It seems you don\'t have access to this module yet, please contact the administrator`,
                JSON.stringify(res)
              );

              reject();

              return promise;
            } else {
              resolve();
            }
          },
          (error) => {
            return throwError(error);
          }
        );
    });

    return promise;
  }
}
