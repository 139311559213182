import { Component, OnInit, Input } from "@angular/core";
import { InspectionDocumentService } from "../services/inspection-document.service";
import { Document } from "../services/document.model";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-inspection-document",
  templateUrl: "./inspection-document.component.html",
  styleUrls: ["./inspection-document.component.scss"],
})
export class InspectionDocumentComponent implements OnInit {
  form: UntypedFormGroup;
  model: any;
  documentData: any[];

  @Input() id_inspection: number;

  constructor(private inspectionDocumentService: InspectionDocumentService) {}

  ngOnInit() {
    if (this.id_inspection) {
      this.loadInspectionDocument(this.id_inspection);
    }
  }

  loadInspectionDocument(inspection) {
    this.model = this.inspectionDocumentService
      .getInspectionDocumentByInspection(inspection)
      .subscribe((inspectionDocument) => {
        this.model = inspectionDocument;
        this.documentData = this.nest(this.model);
      });
  }

  nest(array) {
    let nested = [];
    for (var i = 0; i < array.length; i++) {
      var parent = array[i].id_parent;
      array[i].value =
        array[i].value == 1 ? true : array[i].value == 0 ? false : null;

      if (parent === 0) {
        nested.push(array[i]);
      } else {
        for (var j = 0; j < array.length; j++) {
          if (array[j].id === parent) {
            array[j].children = array[j].children || [];
            array[j].children.push(array[i]);
          }
        }
      }
    }
    return nested;
  }

  unnest(array) {
    let unnested = [];

    for (var i = 0; i < array.length; i++) {
      array[i].value =
        array[i].value == true ? 1 : array[i].value == false ? 0 : null;

      for (var j = 0; j < array[i].children.length; j++) {
        for (var k = 0; k < array[i].children[j].children.length; k++) {
          unnested.push(array[i].children[j].children[k]);
        }
      }
    }
    return unnested;
  }

  submit() {
    let inspectionItems: Document[];

    inspectionItems = this.unnest(this.documentData);

    inspectionItems.forEach((element) => {
      // FIXME: - Fix multiple updates and error message
      this.inspectionDocumentService
        .updateInspectionDocument("", element, element.id)
        .subscribe(
          (res) => {},
          (error) => {}
        );
    });
  }
}
