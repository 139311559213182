import { Component, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { InspectorService } from "../services/inspector.service";
import { DatePipe } from "@angular/common";
import { AngularCsv } from "angular7-csv";
import { DialogService } from "../services/dialog.service";

declare var $: any;

interface JQuery {
  addClass(className: string): JQuery;
  attr(attributeName: string, value: string | number): JQuery;
}
interface JQuery {
  selectpicker(options?: any, callback?: Function): any;
}

interface Inspector {
  name: string;
  id: number;
}

@Component({
  selector: "app-export-inspection",
  templateUrl: "./export-inspection.component.html",
  styleUrls: ["./export-inspection.component.scss"],
})
export class ExportInspectionComponent implements OnInit {
  inspectorOptions: SelectItem[];

  inspectorSelected: Inspector[];

  daterangepickerModel: Date[];

  constructor(
    private inspectorService: InspectorService,
    private datePipe: DatePipe,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.inspectorOptions = [];

    this.inspectorService.getInspectors().subscribe((data) => {
      data.forEach((element) => {
        this.inspectorOptions.push({
          label: element.name,
          value: { id: element.id, name: element.name },
        });
      });

      setTimeout(() => {
        $(".selectpicker").selectpicker("refresh");
      }, 150);
    });
  }

  onSubmit() {
    this.inspectorSelected = $("select").val().map(Number);

    let initialDate = this.datePipe.transform(
      this.daterangepickerModel[0],
      "yyyy-MM-dd"
    );
    let finalDate = this.datePipe.transform(
      this.daterangepickerModel[1],
      "yyyy-MM-dd"
    );

    this.inspectorService
      .export(initialDate, finalDate, this.inspectorSelected)
      .subscribe((data) => {
        let csvOptions = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalseparator: ".",
          showLabels: true,
          showTitle: false,
          title: "PharmacyInspections",
          useBom: true,
          noDownload: false,
          headers: [
            "INSPECTOR_NAME",
            "INSPECTION_DATE",
            "PHARMACY_NAME",
            "STANDARD",
            "SECTION",
            "ITEM",
            "ITEM_VALUE",
          ],
        };

        if (data.length > 0) {
          new AngularCsv(data, "PharmacyInspections", csvOptions);
          this.dialogService.openDialog(
            "Records exported",
            JSON.stringify(data)
          );
        } else {
          this.dialogService.openDialog(
            "No records to be exported",
            JSON.stringify(data)
          );
        }
      });
  }
}
