import { Injectable } from '@angular/core';
import { GlobalSetting } from '../global-setting/global-setting.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment} from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

const API_URL : string = environment.apiUrl + "/global_settings";

@Injectable({
    providedIn: 'root'
 })

export class GlobalSettingService {

    constructor(private http: HttpClient) {

    }

    handleError<T>(error) {
      let errorMessage: any[] = [];
      let errorDetail = '';
      let errorColumn: any;
     
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage[0] = `Error: ${error.error.message}`;
      } else {
        
        errorColumn = error.error;

        const mapped = Object.keys(errorColumn).map(key => ({type: key, value: errorColumn[key]}));

        mapped.forEach( (element) => {
          errorDetail = element.type + " " + element.value[0]
      });

      // server-side error
      errorMessage[0] = `${errorDetail}`;
      errorMessage[1] = `Error Code: ${error.status}\nMessage: ${error.message}\n`;

      }
      return throwError(errorMessage);
    }

    updateGlobalSetting (path:string, body:any, id:any): Observable<GlobalSetting> {
      const url = `${API_URL}/${id}`;
    
      return this.http.put <GlobalSetting>(url, body).pipe(
        catchError(this.handleError));
    }

    getGlobalSetting (id): Observable<GlobalSetting> {
      const url = `${API_URL}/${id}`;
    
      return this.http.get<GlobalSetting>(url, httpOptions).pipe(
        tap(_ => console.log(`loaded global setting id=${id}`)),
        catchError(this.handleError)
      );
    }

} 