import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Inspector } from "../services/inspector.model";
import { InspectorService } from "../services/inspector.service";
import { DialogService } from "../services/dialog.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-inspector",
  templateUrl: "./inspector.component.html",
  styleUrls: ["./inspector.component.scss"],
})
export class InspectorComponent implements OnInit {
  myRowData: Observable<Inspector[]> = null;

  myColDefs = [
    {
      headerName: "Name",
      field: "name",
      suppressSizeToFit: false,
      width: 250,
      checkboxSelection: true,
    },
    { headerName: "Street Address", field: "addressLine1", width: 250 },
    { headerName: "Unit Number", field: "addressLine2", width: 250 },
    { headerName: "City", field: "city", width: 140 },
    { headerName: "Postal Code", field: "postalCode", width: 140 },
    { headerName: "Phone #1", field: "primaryPhone", width: 160 },
    { headerName: "Phone #2", field: "secondaryPhone", width: 160 },
  ];

  gridOptions = {
    // PROPERTIES - object properties, myRowData and myColDefs are created somewhere in your application
    rowData: this.myRowData,
    columnDefs: this.myColDefs,

    // PROPERTIES - simple boolean / string / number properties
    pagination: true,
    rowSelection: "single",
    gridApi: null,
    gridColumnApi: null,

    onGridReady: function (event) {
      this.gridApi = this.api;
      this.gridColumnApi = this.columnApi;
    },
    defaultColDef: {
      sortable: true,
      filter: true,
    },
  };

  constructor(
    private inspectorService: InspectorService,
    private _router: Router,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.myRowData = this.inspectorService.getInspectors();
  }

  onRemoveSelected() {
    // tslint:disable-next-line:prefer-const
    let selectedData = this.gridOptions.gridApi.getSelectedRows();
    const res = this.gridOptions.gridApi.updateRowData({
      remove: selectedData,
    });
    const id = res.remove[0].data.id;

    this.inspectorService.deleteInspector(id).subscribe(
      (res) => {
        this.dialogService.openDialog(
          "Inspector record deleted",
          JSON.stringify(res)
        );
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
        console.log(error);
      }
    );
  }

  onUpdateData() {
    const selectedData = this.gridOptions.gridApi.getSelectedRows();
    const id = selectedData[0].id;

    localStorage.setItem("editInspectorId", id.toString());

    this._router.navigate(["inspector/edit/" + id.toString()]);
  }

  onAddData() {
    this._router.navigate(["inspector/edit"]);
  }
}
