import { Component, OnInit } from "@angular/core";
import { GlobalSetting } from "./global-setting.model";
import { DocumentService } from "../services/document.service";
import { DialogService } from "../services/dialog.service";
import { GlobalSettingService } from "../services/global-setting.service";

@Component({
  selector: "app-global-setting",
  templateUrl: "./global-setting.component.html",
  styleUrls: ["./global-setting.component.scss"],
})
export class GlobalSettingComponent implements OnInit {
  model = new GlobalSetting();
  listInspectionDocuments: any[] = [];

  constructor(
    private inspectionDocumentService: DocumentService,
    private globalSettingService: GlobalSettingService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.listInspectionDocuments = [0];

    this.inspectionDocumentService.getInspectionDocuments().subscribe(
      (data) => {
        this.listInspectionDocuments = data;
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
      }
    );

    // Load record

    this.globalSettingService.getGlobalSetting(1).subscribe((data) => {
      this.model = data;
    });
  }

  public onSubmit() {
    this.globalSettingService
      .updateGlobalSetting("", this.model, this.model.id)
      .subscribe(
        (res) => {
          this.dialogService.openDialog(
            "Global settings updated",
            JSON.stringify(res)
          );
        },
        (error) => {
          console.log(error);

          this.dialogService.openDialogError(error[0], error[1]);
        }
      );
  }
}
