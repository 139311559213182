export class Pharmacy {
    public id: number ;
    public pharmacyName: string;
    public companyName: string;
    public licenseNumber: number;
    public addressLine1: string;
    public addressLine2: string;
    public city: string;
    public country: string;
    public province: string;
    public postalCode: string;
    public primaryPhone: string;
    public secondaryPhone: string;
    public email: string;
    public active: number;
    public status: number;
    public manager: number;
    public alternateManager: number;
    public inspector_id: number;
    public fax: string;
    public website: string;
    public in1touch_id: number;
}
