import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AngularTokenModule } from "angular-token";
import { OauthComponent } from "./oauth/oauth.component";
import { OauthCallbackComponent } from "./oauth-callback/oauth-callback.component";

import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AccordionModule } from "primeng/accordion";

import { MessageService } from "primeng/api";
import { ChartModule } from "primeng/chart";
import { PanelModule } from "primeng/panel";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ToastModule } from "primeng/toast";
import { TreeTableModule } from "primeng/treetable";
import { CardModule } from "primeng/card";
import { DynamicDialogModule } from "primeng/dynamicdialog";

import { AppComponent } from "./app.component";
import { AppMenuComponent, AppSubMenuComponent } from "./menu/menu.component";
import { AppBreadcrumbComponent } from "./app.breadcrumb.component";
import { AppTopBarComponent } from "./toolbar/toolbar.component";
import { AppFooterComponent } from "./app.footer.component";
import { BreadcrumbService } from "./services/breadcrumb.service";

// Application
import { PharmacyComponent } from "./pharmacy/pharmacy.component";
import { PharmacyEditComponent } from "./pharmacy/pharmacy-edit/pharmacy-edit.component";
import { ReportInspSummaryComponent } from "./report-insp-summary/report-insp-summary.component";
import { PhoneMaskDirective } from "./directive/phone-mask.directive";
import { OnlyNumberDirective } from "./directive/only-number-directive";
import { DialogErrorComponent } from "../app/dialogerror/dialog-error-message.component";
import { DialogComponent } from "./dialog/dialog.component";
import { In1touchPharmacyComponent } from "./in1touch-pharmacy/in1touch-pharmacy.component";
import { UserComponent } from "./user/user.component";
import { UserEditComponent } from "./user/user-edit/user-edit.component";

import { AgGridModule } from "ag-grid-angular";

import { InspectorComponent } from "./inspector/inspector.component";
import { DocumentComponent } from "./document/document.component";
import { InspectionDocumentEditComponent } from "./document/document-edit/document-edit.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { InspectorEditComponent } from "./inspector/inspector-edit/inspector-edit.component";
import { GlobalSettingComponent } from "./global-setting/global-setting.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DatePipe } from "@angular/common";
import { ExportInspectionComponent } from "./export-inspection/export-inspection.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { environment } from "../environments/environment";
import { HttpConfigInterceptor } from "../interceptor/httpconfig.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InspectionComponent } from "./inspection/inspection.component";
import { InspectionEditComponent } from "./inspection/inspection-edit/inspection-edit.component";
import { InspectionDocumentComponent } from "./inspection-document/inspection-document.component";
import { HomeComponent } from "../app/home/home.component";
import { RoleGuard } from "./guards/role-guard.service";
import { AuthGuard } from "./guards/auth-guard";
import { OrderModule } from "ngx-order-pipe"; // <-- Import OrderModule
import { ButtonModule } from "primeng/button";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { TabViewModule } from "primeng/tabview";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: environment.apiUrl,
      oAuthBase: environment.apiUrl,
      oAuthPaths: { google_oauth2: "auth/google_oauth2" },
      oAuthWindowType: "sameWindow",
      oAuthCallbackPath: "oauth-callback",
    }),
    FormsModule,
    AccordionModule,
    ChartModule,
    PanelModule,
    ScrollPanelModule,
    ToastModule,
    TreeTableModule,
    AgGridModule.withComponents([]),
    CardModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    OrderModule,
    ButtonModule,
    TriStateCheckboxModule,
    TabViewModule,
    DynamicDialogModule,
  ],
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppFooterComponent,
    PharmacyComponent,
    ReportInspSummaryComponent,
    PharmacyEditComponent,
    In1touchPharmacyComponent,
    PhoneMaskDirective,
    OnlyNumberDirective,
    DialogErrorComponent,
    DialogComponent,
    InspectorComponent,
    DocumentComponent,
    InspectionDocumentEditComponent,
    OauthComponent,
    OauthCallbackComponent,
    PageNotFoundComponent,
    InspectorEditComponent,
    GlobalSettingComponent,
    DashboardComponent,
    ExportInspectionComponent,
    UserComponent,
    UserEditComponent,
    InspectionComponent,
    InspectionEditComponent,
    InspectionDocumentComponent,
    HomeComponent,
  ],
  exports: [PhoneMaskDirective, OnlyNumberDirective],
  providers: [
    BreadcrumbService,
    DatePipe,
    MessageService,
    DialogService,
    DynamicDialogConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    RoleGuard,
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
