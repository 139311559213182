

<section class="container-header">

        <!-- <div class="div-title"> -->
                <div class="well" >
                    <div class="aaa">
                        <h3>Inspections Summary</h3>
                    </div>
                </div>
            <!-- </div> -->

    <div class="one">
        <h5>Inspections - Standards (Summary)</h5>
        <p-chart type="bar" [width]="65" [height]="270" [data]="data" [options]="options"></p-chart>
    </div>
    <div></div>
    <div class="two">
        <h5>Inspections - Standard 1: General</h5>
        <p-chart type="bar" [width]="65" [height]="270" [data]="data2" [options]="options"></p-chart>
  
    </div>

    <div></div>

    <div class="one">
        <h5>Inspections - Standard 2: Staff</h5>
        <p-chart type="bar" [width]="65" [height]="270" [data]="data3" [options]="options"></p-chart>
    </div>
    <div></div>
    <div class="two">
        <h5>Inspections - Standard 3: Standards of Practice</h5>
        <p-chart type="bar" [width]="65" [height]="270" [data]="data4" [options]="options"></p-chart>
    </div> 


    <!-- <ag-grid-angular 
    id="myGrid"
    style="width: 100%; height: 100%;" 
    class="ag-theme-balham"
    [enableSorting]="true"
    [enableFilter]="true"
    [rowData]="rowData | async" 
    [columnDefs]="columnDefs"
    [paginationAutoPageSize]="true"
    [pagination]="true">
    </ag-grid-angular> -->

</section>

  
  


  
