import { Component, OnInit, ViewChild } from "@angular/core";
import { InspectionService } from "../../services/inspection.service";
import { DocumentService } from "../../services/document.service";
import { PharmacyService } from "../../services/pharmacy.service";
import { Inspection } from "../../services/inspection.model";
import { Router } from "@angular/router";
import { DialogService } from "../../services/dialog.service";
import { GlobalSettingService } from "../../services/global-setting.service";
import { InspectionDocumentComponent } from "../../inspection-document/inspection-document.component";
import { InspectorService } from "../../services/inspector.service";
import { UserService } from "../../services/user.service";
import { UserRole } from "../../services/enumeration";

@Component({
  selector: "app-inspection-edit",
  templateUrl: "./inspection-edit.component.html",
  styleUrls: ["./inspection-edit.component.scss"],
})
export class InspectionEditComponent implements OnInit {
  model = new Inspection();
  listDocuments: any;
  listPharmacies: any;
  listInspectors: any;
  id_inspection: string = "";
  isInspector: boolean;
  private currentUser: string;

  @ViewChild(InspectionDocumentComponent, { static: false })
  inspectionDocumentComponent: InspectionDocumentComponent;

  constructor(
    private router: Router,
    private inspectionService: InspectionService,
    private pharmacyService: PharmacyService,
    private globalSettingService: GlobalSettingService,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private inspectorService: InspectorService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.id_inspection = localStorage.getItem("editInspectionId");

    if (!this.id_inspection) {
      this.model = new Inspection();
      this.model.inspection_date = new Date();

      this.userService.getCurrentUser().subscribe((res) => {
        this.currentUser = res[0].name;
        this.isInspector = res[0].role === UserRole.INSPECTOR ? true : false;

        if (this.isInspector) {
          this.model.inspector_id = res[0].inspector_id;
        }
      });
    } else {
      this.userService.getCurrentUser().subscribe((res) => {
        this.currentUser = res[0].name;
      });

      this.inspectionService
        .getInspection(this.id_inspection)
        .subscribe((data) => {
          this.model = data;
        });
    }

    localStorage.removeItem("editInspectionId");

    // List documents DocumentService

    this.pharmacyService.getPharmacies().subscribe(
      (data) => {
        this.listPharmacies = data.filter((pharmacy) => pharmacy.status === 1);
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
      }
    );

    this.inspectorService.getInspectors().subscribe(
      (data) => {
        this.listInspectors = data;
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
      }
    );

    this.documentService.getInspectionDocuments().subscribe(
      (data) => {
        this.listDocuments = data;
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
      }
    );

    this.globalSettingService.getGlobalSetting(1).subscribe(
      (data) => {
        this.model.document_id = data.idPpaVersion;
      },
      (error) => {
        this.dialogService.openDialogError(error[0], error[1]);
      }
    );
  }

  public previousForm() {
    this.router.navigateByUrl("/inspection");
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  public onSubmit() {
    if (this.model.id) {
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, -1);

      this.model.updated_at = localISOTime;
      this.model.updated_by = this.currentUser;

      this.inspectionService
        .updateInspection(this.model, this.model.id)
        .subscribe(
          (res) => {
            this.inspectionDocumentComponent.submit();

            this.dialogService.openDialog(
              "Inspection record updated",
              JSON.stringify(res)
            );
          },
          (error) => {
            console.log(error);
            this.dialogService.openDialogError(error[0], error[1]);
          },
          () => {
            this.model = new Inspection();
            this.previousForm();
          }
        );
    } else {
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, -1);

      this.model.created_at = localISOTime;
      this.model.updated_at = localISOTime;
      this.model.created_by = this.currentUser;
      this.model.updated_by = this.currentUser;

      this.inspectionService.addInspection(this.model).subscribe(
        (res) => {
          this.inspectionDocumentComponent.loadInspectionDocument(res.id);

          this.dialogService.openDialog(
            "Inspection record created",
            JSON.stringify(res)
          );

          // Todo - This is ugly, change it asap!!!
          localStorage.setItem("editInspectionId", String(res.id));
        },
        (error) => {
          this.dialogService.openDialogError(error[0], error[1]);
        },
        () => {
          this.model.id = parseInt(localStorage.getItem("editInspectionId"));
          localStorage.removeItem("editInspectionId");
        }
      );
    }
  }
}
