import { Injectable } from "@angular/core";
import { Inspection } from "./inspection.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
// import { identifierModuleUrl } from '@angular/compiler';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const API_URL: string = environment.apiUrl + "/inspections";

@Injectable({
  providedIn: "root",
})
export class InspectionService {
  constructor(private http: HttpClient) {}

  handleError<T>(error) {
    let errorMessage: any[] = [];
    let errorDetail = "";
    let errorColumn: any;

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage[0] = `Error: ${error.error.message}`;
    } else {
      errorColumn = error.error;

      const mapped = Object.keys(errorColumn).map((key) => ({
        type: key,
        value: errorColumn[key],
      }));

      mapped.forEach((element) => {
        errorDetail = element.type + " " + element.value[0];
      });

      // server-side error
      errorMessage[0] = `${errorDetail}`;
      errorMessage[1] = `Error Code: ${error.status}\nMessage: ${error.message}\n`;
    }
    return throwError(errorMessage);
  }

  addInspection(body: any): Observable<Inspection> {
    const url = `${API_URL}/`;

    return this.http
      .post<Inspection>(url, body)
      .pipe(catchError(this.handleError));
  }

  updateInspection(body: any, id: any): Observable<Inspection> {
    const url = `${API_URL}/${id}`;

    return this.http
      .put<Inspection>(url, body)
      .pipe(catchError(this.handleError));
  }

  deleteInspection(id): Observable<Inspection> {
    const url = `${API_URL}/${id}`;

    return this.http
      .delete<Inspection>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getInspection(id): Observable<Inspection> {
    const url = `${API_URL}/${id}`;

    return this.http.get<Inspection>(url, httpOptions).pipe(
      tap((_) => console.log(`loaded Inspection id=${id}`)),
      catchError(this.handleError)
    );
  }

  getCountCurrentYear(): Observable<BigInteger> {
    const url = `${API_URL}/getCountCurrentYear`;

    return this.http
      .get<BigInteger>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getCountCurrentMonth(): Observable<BigInteger> {
    const url = `${API_URL}/getCountCurrentMonth`;

    return this.http
      .get<BigInteger>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getLastInspectionDate(): Observable<BigInteger> {
    const url = `${API_URL}/getLastInspectionDate`;

    return this.http
      .get<BigInteger>(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getInspections(): Observable<Inspection[]> {
    return this.http
      .get<Inspection[]>(API_URL)
      .pipe(catchError(this.handleError));
  }

  getInspectionsByInspector(id): Observable<Inspection[]> {
    const url = `${API_URL + "/getInspectionsByInspector"}?inspector_id=${id}`;

    return this.http.get<Inspection[]>(url).pipe(
      tap((heroes) => console.log("fetched Inspections")),
      catchError(this.handleError)
    );
  }
}
